import { useState } from 'react';
import {
  Modal,
  Card,
  Typography,
  Box,
  IconButton,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Alert
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  p: 3,
  maxHeight: '90vh',
  overflow: 'auto'
};

const AddRoleModal = ({ open, onClose, permissions = [], onSubmit }) => {
  const [roleName, setRoleName] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [error, setError] = useState('');

  const handlePermissionChange = (permissionId) => {
    setSelectedPermissions(prev => {
      if (prev.includes(permissionId)) {
        return prev.filter(id => id !== permissionId);
      } else {
        return [...prev, permissionId];
      }
    });
  };

  const handleSubmit = () => {
    if (!roleName.trim()) {
      setError('Role name is required');
      return;
    }

    if (selectedPermissions.length === 0) {
      setError('Please select at least one permission');
      return;
    }

    onSubmit({
      name: roleName.trim(),
      permissions: selectedPermissions,
    });

    // Reset form
    setRoleName('');
    setSelectedPermissions([]);
    setError('');
  };

  const handleClose = () => {
    // Reset form on close
    setRoleName('');
    setSelectedPermissions([]);
    setError('');
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-role-modal"
    >
      <Card sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h6" component="h2" fontWeight="bold">
            Add New Role
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box mb={3}>
          <TextField
            label="Role Name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            fullWidth
            required
            variant="outlined"
            error={!!error && !roleName.trim()}
            helperText={error && !roleName.trim() ? 'Role name is required' : ''}
          />
        </Box>

        <Box mb={3}>
          <Typography variant="subtitle2" color="text.secondary" mb={2}>
            Select Permissions
          </Typography>
          <FormGroup>
            {permissions.map((permission) => (
              <FormControlLabel
                key={permission.id}
                control={
                  <Checkbox
                    checked={selectedPermissions.includes(permission.id)}
                    onChange={() => handlePermissionChange(permission.id)}
                  />
                }
                label={permission.name}
              />
            ))}
          </FormGroup>
        </Box>

        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Create Role
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

AddRoleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

AddRoleModal.defaultProps = {
  permissions: []
};

export default AddRoleModal;
