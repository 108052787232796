/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LinearProgress,
  ListItem,
  Typography,
  List,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loader from '../Loader';
import moment from 'moment/moment';

function SubscriptionDetail(props) {
  const { artisan } = props;
  const [loading] = useState(false);
  const [skills, setSkills] = useState();

  const fetchJobSkills = async () => {
    const res = artisan?.profile?.skills?.map((item) => item?.name);
    setSkills(res);
  };
  useEffect(() => {
    fetchJobSkills();
  }, [artisan]);

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box alignItems="center" justifyContent="center" display="flex">
            <Typography
              id="modal-modal-title"
              variant="h5"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Subscription Detail
            </Typography>
          </Box>

          <Grid container>
            <Grid item lg={8} sm={12}>
              <Typography variant="h6">
                Skill:{' '}
                <Typography variant="p" color={'primary'}>
                  {skills?.join(', ') || 'No skill listed'}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Grid lg={12}>
            <LinearProgress
              sx={{ borderRadius: 5, height: 2 }}
              value={'100%'}
              variant={'determinate'}
            />
          </Grid>

          <Grid container mt={1}>
            <Grid item lg={6} md={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Email:
                <Typography color="secondary" variant="span" pl={1}>
                  {artisan?.email || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Artisan:
                <Typography color="secondary" variant="span" pl={1}>
                  {artisan?.first_name + ' ' + artisan?.last_name || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Country / State:
                <Typography color="secondary" variant="span" pl={1}>
                  {(artisan?.profile?.country || 'N/A') +
                    ' / ' +
                    (artisan?.profile?.city || 'N/A')}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Business name:
                <Typography color="secondary" variant="span" pl={1}>
                  {artisan?.profile?.business_name || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Business RN:
                <Typography color="secondary" variant="span" pl={1}>
                  {artisan?.profile?.business_registration_number || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Years of experience:
                <Typography color="secondary" variant="span" pl={1}>
                  {artisan?.professional_training?.years_of_experience || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Phone:
                <Typography color="secondary" variant="span" pl={1}>
                  {artisan?.phone || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Average rating:
                <Typography color="secondary" variant="span" pl={1}>
                  {artisan?.average_rating || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Accordion sx={{ marginTop: '10px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Professional training: </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container mt={-3}>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Certificate:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.professional_training?.certificate || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Highest Education Level:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.professional_training
                        ?.highest_education_level || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Institution:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.professional_training?.institution || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Skill acquisition mode:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.professional_training?.skill_acquisition_mode ||
                        'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ marginTop: '10px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Community Ties Proof: </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container mt={-3}>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Character reference contact:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.community_ties_proof
                        ?.character_reference_contact || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Character reference name:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.community_ties_proof
                        ?.character_reference_name || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Community org. member:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.community_ties_proof
                        ?.is_community_org_member === true
                        ? 'True'
                        : 'False'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Years at community org:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.community_ties_proof?.years_at_community_org ||
                        'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Next of kin name:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.community_ties_proof?.next_of_kin_name || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                  <Typography
                    variant="p"
                    color="primary"
                    pt={3}
                    pl={'3px'}
                    sx={{ float: 'left' }}
                  >
                    Next of kin phone:
                    <Typography color="secondary" variant="span" pl={1}>
                      {artisan?.community_ties_proof
                        ?.next_of_kin_phone_number || 'N/A'}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ marginTop: '10px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Working Hours: </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {artisan?.working_hours ? (
                  artisan?.working_hours?.map((workingHour, key) => (
                    <ListItem disablePadding key={key}>
                      <Typography color="secondary" mr={2}>
                        {workingHour.day}
                      </Typography>
                      <Typography color="secondary">
                        {workingHour.opening_time} -{' '}
                      </Typography>
                      <Typography color="secondary">
                        {' '}
                        {workingHour.closing_time}
                      </Typography>
                    </ListItem>
                  ))
                ) : (
                  <Typography color="secondary">N/A</Typography>
                )}
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ marginTop: '10px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Competency references</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Subscription Module</TableCell>
                      <TableCell>Identifier ID</TableCell>
                      <TableCell>Contact Details</TableCell>
                      <TableCell>Payment Method</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {artisan?.competency_references?.slice(-3).map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>{row.location}</TableCell>
                        <TableCell>{row.phone_number}</TableCell>
                        <TableCell>
                          {moment(row.created_at).format('ll')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* </List> */}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ marginTop: '10px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">Available Locations: </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {artisan?.locations ? (
                  artisan?.locations.map((location, key) => (
                    <ListItem disablePadding key={key}>
                      <Typography color="secondary" component="span" mr={3}>
                        {location.name}
                      </Typography>
                    </ListItem>
                  ))
                ) : (
                  <Typography color="secondary">N/A</Typography>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Box>
  );
}

export default SubscriptionDetail;
