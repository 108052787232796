import axios from 'axios';

export async function registerCustomer (customerData) {
  const { firstName, lastName, phone, email, password } = customerData;
  const customer = {
    first_name: firstName,
    last_name: lastName,
    phone,
    email,
    password,
    user_type: 'admin',
    confirm_password: password
  };

  try {
    const response = await axios.post('/register', customer);
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to get you registered. Please try again or contact us support@fundis.com'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function loginCustomer (customerData) {
  const { username, password } = customerData;
  const customer = {
    username,
    password,
    user_type: 'admin'
  };

  try {
    const response = await axios.post('/login', customer);
    return response.data;
  } catch (err) {
    console.log(err);
    const response = err?.response;
    const data = response?.data;

    const status = response?.status || 500;
    const message = status !== 500 ? data?.message : 'We are unable to log you in now. Please try again or contact us support@fundis.com';

    return {
      error: { message },
      errors: data?.data
    };
  }
}

export async function forgotPassword (customerPhoneNumber) {
  const { phone } = customerPhoneNumber;
  const customer = {
    phone
  };

  try {
    const response = await axios.post('/forgot-password', customer);
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to reset your password now. Please try again or contact us support@fundis.com'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function resetPassword (customerPhoneNumber) {
  const { password, confirmPassword } = customerPhoneNumber;

  const otp = JSON.parse(localStorage.getItem('otp'))?.otp;
  const phoneNumber = JSON.parse(localStorage.getItem('phoneNumber'))?.phone;

  const customer = {
    password,
    confirm_password: confirmPassword,
    otp
  };

  try {
    const response = await axios.post(
      `/reset-password/${phoneNumber}`,
      customer
    );
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to reset your password now. Please try again or contact us support@fundis.com'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function verifyOTP (userOtp) {
  const { otp } = userOtp;

  const customer = {
    otp
  };

  try {
    const response = await axios.post('/otp/verify', customer, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to verify your otp now. Please try again or contact us support@fundis.com'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function resendOTP () {
  let data;

  try {
    const response = await axios.post('/otp/resend', data);
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to your OTP now. Please try again or contact us support@fundis.com'
            : data.message
      },
      errors: data.data
    };
  }
}
