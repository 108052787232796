import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Modal,
  Card,
  Alert,
  CardContent,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton,
} from '@mui/material';
import validator from 'validator';
import { Loader, DataGrid } from '../components';
import { fetchAvailableRoles, fetchAllAssessors } from '../api';
import { AddCircle, Delete } from '@mui/icons-material';
import phone from 'phone';
import { createAdmin, deleteAdmin } from '../api/roleManagement';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Index() {
  const [assessorData, setAssessorData] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageError, setPageError] = useState('');
  const [pageMessage, setPageMessage] = useState('');
  const [pageSize] = useState(15);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(15);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user_type: '',
      role: 'assessor',
    },
  });

  useEffect(() => {
    setLoading(true);
    fetchAllAssessors({ page: page + 1 }).then((data) => {
      if (data?.data?.data) {
        setAssessorData(data.data.data);
        setRowCount(data.data.total || data.data.data.length);
      }
      setLoading(false);
    });
  }, [page]);

  const getPage = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchAvailableRoles().then((data) => {
      setAvailableRoles(data?.data?.roles);
    });
  }, [page]);

  const columns = [
    { field: 'fundis_id', headerName: 'Assessor ID', width: 200 },
    {
      field: 'name',
      headerName: 'Name',
      width: 160,
      valueGetter: (params) =>
        `${params.row.first_name} ${params.row.last_name}`,
    },
    { field: 'phone', headerName: 'Phone', width: 160 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'is_active',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => (
        <Typography color={params.value ? 'success.main' : 'error.main'}>
          {params.value ? 'Active' : 'Inactive'}
        </Typography>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Date Created',
      width: 160,
      valueGetter: (params) => moment(params.value).format('ll'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      renderCell: (row) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Tooltip title="Delete">
            <IconButton disabled onClick={() => handleDeleteConfirmOpen(row)}>
              <Delete />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Update">
            <IconButton onClick={() => onUpdate && onUpdate(row)}>
              <SettingsOutlined />
            </IconButton>
          </Tooltip> */}
        </Box>
      ),
    },
  ];

  const handleDeleteConfirmed = async () => {
    setLoading(true);
    const response = await deleteAdmin(selectedRow);
    setLoading(false);
    if (response?.error) {
      const { error } = response;
      setPageError(error?.message);
      return;
    }

    if (!response?.success || response?.message) {
      setPageError(response?.message);
    }
    if (response?.success) {
      setPageMessage(`Successfully deleted ${selectedRow.firstName} `);
      setOpen(false);
    }
    handleDeleteConfirmClose();
    window.location.reload();
  };

  const handleDeleteConfirmOpen = (row) => {
    const check = JSON.parse(localStorage.getItem('user'));
    if (row?.email === check?.email) {
      toast.error('You cannot delete this user');
    } else {
      setSelectedRow(row);
      setDeleteDialogOpen(true);
    }
  };

  const handleDeleteConfirmClose = () => {
    setDeleteDialogOpen(false);
    setSelectedRow(null);
  };

  const userTypeOptions = ['assessor'];
  const selectedUserType = watch('user_type');

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center',
  };

  const handleAddMore = () => {
    setOpen(true);
  };

  const addMore = async (data) => {
    if (data.password === data.confirm_password) {
      setLoading(true);
      const response = await createAdmin({
        ...data,
        phone: phone(`+254-${data.phone}`).phoneNumber,
        user_type: data.user_type,
        role: data.user_type === 'assessor' ? 'assessor' : data.role,
      });

      setLoading(false);

      if (response?.error) {
        const { error } = response;

        setPageError(error?.message);
        return;
      }

      if (response?.success) {
        setPageMessage(
          `Successfully created an account for ${response?.data?.first_name}`
        );
        setOpen(false);
      }
      window.location.reload();
    } else {
      setPageError('Password and Confirm password do not match');
    }
  };
  return (
    <>
      {loading && <Loader />}
      <Grid minHeight="100vh" width={'100%'} container>
        <Grid sm={8} lg={12} mt={{ lg: 4 }} marginX="20px" container p={3}>
          <Box
            width="100%"
            minHeight="90vh"
            display="flex"
            flexDirection="column"
            maxWidth={{ lg: 'lg', xl: 'lg' }}
            p={5}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={4}
            >
              <Button
                variant="outlined"
                fontWeight="bold"
                onClick={() => handleAddMore()}
                // disabled={userType !== '"super-admin"'}
              >
                <AddCircle /> Add New Assessor
              </Button>
            </Box>
            <Box width="100%">
              <DataGrid
                rowCount={rowCount}
                rows={assessorData}
                columns={columns}
                loading={loading}
                pageSize={pageSize}
                onPageChange={getPage}
                page={page}
                autoHeight
                rowsPerPageOptions={[15]}
                paginationMode="server"
                getRowClassName={(params) =>
                  params?.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { lg: 600, md: 300 }, padding: 'auto' }}>
          {pageError && <Alert severity="error">{pageError}</Alert>}
          {pageMessage && <Alert severity="success">{pageMessage}</Alert>}
          <Grid mb={1}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Register New Assessor
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(addMore)}>
            <CardContent sx={{ padding: 5 }}>
              <Grid container>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="First Name"
                    required={true}
                    fullWidth
                    {...register('first_name', {
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.first_name?.message}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="Last Name"
                    required={true}
                    fullWidth
                    {...register('last_name', {
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.last_name?.message}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="Email"
                    required={true}
                    fullWidth
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'email is required',
                      },

                      validate: (value) => validator.isEmail(value),
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.email?.message}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    type="tel"
                    label="Phone Number"
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+254</InputAdornment>
                      ),
                    }}
                    {...register('phone', {
                      required: {
                        value: true,
                        message: 'Phone number is required',
                      },
                      maxLength: {
                        value: 10,
                        message: 'Phone number cannot exceed 11 digits',
                      },
                      validate: (value) =>
                        validator.isMobilePhone(value) &&
                        phone(`+254-${value}`).isValid,
                    })}
                    required={!!errors.phone}
                    fullWidth
                  />
                  <Typography variant="small" component="small" color="error">
                    {errors.phone?.message}
                    {errors.phone &&
                      errors.phone.type === 'validate' &&
                      'Enter a valid phone number'}
                  </Typography>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    type={'text'}
                    label="Password"
                    variant="standard"
                    required={true}
                    {...register('password', {
                      required: {
                        value: true,
                        message: 'Password cannot be empty',
                      },
                      minLength: {
                        value: 8,
                        message: 'Password cannot be less than 8 characters',
                      },
                      validate: (value) =>
                        validator.isStrongPassword(value, {
                          minLength: 8,
                          minLowercase: 1,
                          minUppercase: 1,
                          minNumbers: 1,
                          minSymbols: 0,
                        }),
                    })}
                    fullWidth
                  />
                  <Typography variant="small" component="small" color="error">
                    {errors.password?.message}
                    {errors.password &&
                      errors.password.type === 'validate' &&
                      'Password must contain a lowercase (a-z), an uppercase (A-Z) and a number (0-9)'}
                  </Typography>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    type={'text'}
                    label="Confirm Password"
                    variant="standard"
                    required={true}
                    {...register('confirm_password', {
                      required: {
                        value: true,
                        message: 'Password cannot be empty',
                      },
                      minLength: {
                        value: 8,
                        message: 'Password cannot be less than 8 characters',
                      },
                      validate: (value) =>
                        validator.isStrongPassword(value, {
                          minLength: 8,
                          minLowercase: 1,
                          minUppercase: 1,
                          minNumbers: 1,
                          minSymbols: 0,
                        }),
                    })}
                    fullWidth
                  />
                  <Typography variant="small" component="small" color="error">
                    {errors.password?.message}
                    {errors.password &&
                      errors.password.type === 'validate' &&
                      'Password must contain a lowercase (a-z), an uppercase (A-Z) and a number (0-9)'}
                  </Typography>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="Address"
                    required={true}
                    fullWidth
                    {...register('address', {
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.address?.message}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="User Type"
                    select
                    fullWidth
                    {...register('user_type', {
                      required: 'User type is required',
                    })}
                    error={!!errors.user_type}
                  >
                    {userTypeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {errors.user_type?.message}
                    </Typography>
                  </Box>
                </Grid>

                {selectedUserType === 'admin' && (
                  <Grid item lg={6} p={2}>
                    <TextField
                      variant="standard"
                      label="Admin Role"
                      select
                      fullWidth
                      {...register('role', {
                        required: 'Role is required',
                      })}
                      error={!!errors.role}
                    >
                      {availableRoles.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Box mt={1}>
                      <Typography
                        variant="small"
                        component="small"
                        color="error"
                      >
                        {errors.role?.message}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Grid mt={3}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ width: 300, margin: 'auto' }}
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Card>
      </Modal>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selectedRow?.first_name}{' '}
            {selectedRow?.last_name}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Index;
