import axios from 'axios';

export async function fetchAllCustomers(params = {}) {
  let url = `/admin/users/customer?page=${params.page || 1}`;

  if (params.name) url += `&name=${encodeURIComponent(params.name)}`;
  if (params.email) url += `&email=${encodeURIComponent(params.email)}`;
  if (params.phone) url += `&phone=${encodeURIComponent(params.phone)}`;
  if (params.idNumber) {
    url += `&id_number=${encodeURIComponent(params.idNumber)}`;
  }
  if (params.isActive) {
    url += `&is_active=${encodeURIComponent(params.isActive)}`;
  }
  if (params.gender) url += `&gender=${encodeURIComponent(params.gender)}`;
  if (params.locationId) {
    url += `&location_id=${encodeURIComponent(params.locationId)}`;
  }

  try {
    const response = await axios.get(url);
    console.log('API Response:', response); // Debugging: Check API response
    return response.data || {}; // Return empty object if data is undefined
  } catch (error) {
    console.error('Error fetching customers:', error);
    return error.response?.data || { success: false, message: 'API Error' };
  }
}

export async function fetchOneCustomer(id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/users/customer/${id}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function reassignJob(jobId, artisanId) {
  const token = localStorage.getItem('token');

  const data = {
    artisan_id: artisanId,
  };
  try {
    const response = await axios({
      method: 'patch',
      data,
      url: `/admin/job-request/${jobId}/reassign`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? data.message
            : 'We are unable to reassign/assign an artisan now. Please contact the tech team',
      },
      errors: data.data,
    };
  }
}

export async function fetchOneArtisan(id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/users/artisan/${id}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllArtisans({
  page = 1,
  name = '',
  email = '',
  phone = '',
  idNumber = '',
  isActive = '',
  locationId = '',
  skillId = '',
  subscriptionName = '',
  subscriptionBalance = '',
  usertype = '',
  startDate = '',
}) {
  let url = `/admin/users/artisan?page=${page}`;
  if (name) url += `&name=${encodeURIComponent(name)}`;
  if (email) url += `&email=${encodeURIComponent(email)}`;
  if (phone) url += `&phone=${encodeURIComponent(phone)}`;
  if (idNumber) url += `&id_number=${encodeURIComponent(idNumber)}`;
  if (isActive) url += `&is_active=${encodeURIComponent(isActive)}`;
  if (locationId) url += `&location_id=${encodeURIComponent(locationId)}`;
  if (skillId) url += `&skill_id=${skillId}`;
  if (startDate) url += `&start_date=${startDate}`;
  if (subscriptionName) url += `&subscription_name=${subscriptionName}`;
  if (subscriptionBalance) {
    url += `&subscription_balance=${subscriptionBalance}`;
  }
  if (usertype) url += `&user_type=${usertype}`;
  try {
    const response = await axios({
      method: 'get',
      url,
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error.message;
  }
}

export async function assignPlan(data) {
  try {
    const response = await axios.post('/admin/subscription/assign-plan', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function fetchAllPlans() {
  try {
    const response = await axios.get('/admin/subscription/plans', {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    const { response } = error || {};
    const { data, status } = response || {};

    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, please contact the system administrator'
            : data?.message || 'Failed to fetch plans',
      },
      errors: data?.data || [],
    };
  }
}

export async function createPlan(planData) {
  try {
    const response = await axios.post('/admin/subscription/plans', planData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    const { response } = error || {};
    const { data, status } = response || {};

    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, please contact the system administrator'
            : data?.message || 'Failed to create plan',
      },
      errors: data?.data || [],
    };
  }
}

export async function updatePlan(planId, planData) {
  try {
    const response = await axios.put(
      `/admin/subscription/plans/${planId}`,
      planData,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    return response.data;
  } catch (error) {
    const { response } = error || {};
    const { data, status } = response || {};

    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, please contact the system administrator'
            : data?.message || 'Failed to update plan',
      },
      errors: data?.data || [],
    };
  }
}

export async function fetchAllQuotations(pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/quotations?status=pending&page=${pageNo}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function searchAllQuotations(searchParam) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/quotation/${searchParam}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function searchAllExternalQuotations(searchParam) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/quotation/external-quotation/${searchParam}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllExternalQuotations(pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/quotation/external-quotations?status=pending&page=${pageNo}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllSuccessReceipts(pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/transactions?status=SUCCESSFUL&page=${pageNo}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllReceipts(pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/transactions?page=${pageNo}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deactivateOneCustomer(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `/admin/deactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function activateOneCustomer(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `/admin/reactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteOneCustomer(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'DELETE',
      url: `/admin/delete/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllInvoices(pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/invoices?page=${pageNo}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllExternalInvoices(pageNo = 1) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/invoices/external-invoices?page=${pageNo}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function searchAllInvoices(searchParam) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/invoices/${searchParam}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function searchAllExternalInvoices(searchParam) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/invoices/external-invoice/${searchParam}`,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deactivateOneArtisan(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `/admin/deactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function activateOneArtisan(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `/admin/reactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteOneArtisan(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'DELETE',
      url: `/admin/delete/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
