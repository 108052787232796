import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Modal,
  Card,
  Alert,
  CardContent,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import validator from 'validator';
import { Loader, AdminTable } from '../components';
import { fetchAllAdmins, fetchAvailableRoles } from '../api';
import { AddCircle } from '@mui/icons-material';
import phone from 'phone';
import { createAdmin } from '../api/roleManagement';

function Index() {
  const [adminData, setAdminData] = useState();
  const [availableRoles, setAvailableRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageError, setPageError] = useState('');
  const [pageMessage, setPageMessage] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user_type: '',
      role: '',
      role_id: '',
    },
  });

  useEffect(() => {
    fetchAllAdmins().then((data) => {
      setAdminData(data?.data?.data);
    });

    fetchAvailableRoles().then((data) => {
      setAvailableRoles(data?.data?.roles);
    });
  }, []);

  const userTypeOptions = ['admin'];
  const selectedRole = watch('role');

  const [tempRoleId, setTempRoleId] = useState();
  useEffect(() => {
    const roleId = availableRoles.find(
      (role) => role.name === selectedRole
    )?.id;
    setTempRoleId(roleId);
    register('role_id', { value: roleId });
  }, [selectedRole, availableRoles, register]);

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center',
  };

  const handleAddMore = () => {
    setOpen(true);
  };

  const addMore = async (data) => {
    if (data.password === data.confirm_password) {
      setLoading(true);
      const response = await createAdmin({
        ...data,
        phone: phone(`+254-${data.phone}`).phoneNumber,
        user_type: data.user_type,
        role: String(data.role),
        role_id: String(tempRoleId),
      });

      setLoading(false);

      if (response?.error) {
        const { error } = response;

        setPageError(error?.message);
        return;
      }

      if (response?.success) {
        setPageMessage(
          `Successfully created an account for ${response?.data?.first_name}`
        );
        setOpen(false);
      }
      window.location.reload();
    } else {
      setPageError('Password and Confirm password do not match');
    }
  };
  return (
    <>
      {loading && <Loader />}
      <Grid minHeight="100vh" container>
        <Grid sm={8} lg={7} mt={{ lg: 4 }} marginX="20px" container p={3}>
          <Box width="100%" minHeight="90vh">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={4}
            >
              <Button
                variant="outlined"
                fontWeight="bold"
                onClick={() => handleAddMore()}
                // disabled={userType !== '"super-admin"'}
              >
                <AddCircle /> Add New Admin
              </Button>
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={'50px'}>
              <AdminTable
                roles={adminData}
                availableRoles={availableRoles}
                headers={[
                  'Name',
                  'ID',
                  'Phone Number',
                  'Email',
                  'Role',
                  'Data Created',
                ]}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { lg: 600, md: 300 }, padding: 'auto' }}>
          {pageError && <Alert severity="error">{pageError}</Alert>}
          {pageMessage && <Alert severity="success">{pageMessage}</Alert>}
          <Grid mb={1}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Register New Admin
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(addMore)}>
            <CardContent sx={{ padding: 5 }}>
              <Grid container>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="First Name"
                    required={true}
                    fullWidth
                    {...register('first_name', {
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.first_name?.message}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="Last Name"
                    required={true}
                    fullWidth
                    {...register('last_name', {
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.last_name?.message}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="Email"
                    required={true}
                    fullWidth
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'email is required',
                      },

                      validate: (value) => validator.isEmail(value),
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.email?.message}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    type="tel"
                    label="Phone Number"
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+254</InputAdornment>
                      ),
                    }}
                    {...register('phone', {
                      required: {
                        value: true,
                        message: 'Phone number is required',
                      },
                      maxLength: {
                        value: 10,
                        message: 'Phone number cannot exceed 11 digits',
                      },
                      validate: (value) =>
                        validator.isMobilePhone(value) &&
                        phone(`+254-${value}`).isValid,
                    })}
                    required={!!errors.phone}
                    fullWidth
                  />
                  <Typography variant="small" component="small" color="error">
                    {errors.phone?.message}
                    {errors.phone &&
                      errors.phone.type === 'validate' &&
                      'Enter a valid phone number'}
                  </Typography>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    type={'text'}
                    label="Password"
                    variant="standard"
                    required={true}
                    {...register('password', {
                      required: {
                        value: true,
                        message: 'Password cannot be empty',
                      },
                      minLength: {
                        value: 8,
                        message: 'Password cannot be less than 8 characters',
                      },
                      validate: (value) =>
                        validator.isStrongPassword(value, {
                          minLength: 8,
                          minLowercase: 1,
                          minUppercase: 1,
                          minNumbers: 1,
                          minSymbols: 0,
                        }),
                    })}
                    fullWidth
                  />
                  <Typography variant="small" component="small" color="error">
                    {errors.password?.message}
                    {errors.password &&
                      errors.password.type === 'validate' &&
                      'Password must contain a lowercase (a-z), an uppercase (A-Z) and a number (0-9)'}
                  </Typography>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    type={'text'}
                    label="Confirm Password"
                    variant="standard"
                    required={true}
                    {...register('confirm_password', {
                      required: {
                        value: true,
                        message: 'Password cannot be empty',
                      },
                      minLength: {
                        value: 8,
                        message: 'Password cannot be less than 8 characters',
                      },
                      validate: (value) =>
                        validator.isStrongPassword(value, {
                          minLength: 8,
                          minLowercase: 1,
                          minUppercase: 1,
                          minNumbers: 1,
                          minSymbols: 0,
                        }),
                    })}
                    fullWidth
                  />
                  <Typography variant="small" component="small" color="error">
                    {errors.password?.message}
                    {errors.password &&
                      errors.password.type === 'validate' &&
                      'Password must contain a lowercase (a-z), an uppercase (A-Z) and a number (0-9)'}
                  </Typography>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="Address"
                    required={true}
                    fullWidth
                    {...register('address', {
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.address?.message}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="User Type"
                    select
                    fullWidth
                    {...register('user_type', {
                      required: 'User type is required',
                    })}
                    error={!!errors.user_type}
                  >
                    {userTypeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {errors.user_type?.message}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="Role"
                    select
                    fullWidth
                    {...register('role', {
                      required: 'Role is required',
                    })}
                    error={!!errors.role}
                  >
                    {availableRoles.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {errors.role?.message}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <Grid mt={3}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ width: 300, margin: 'auto' }}
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Card>
      </Modal>
    </>
  );
}

export default Index;
