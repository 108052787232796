/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Modal,
  Grid,
  Button,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Card,
  Alert,
  CardContent,
} from '@mui/material';
import { fetchAllArtisans, fetchOneArtisan, exportUserData, getLocations, getRequestTypes as getSkills, fetchAllPlans, assignPlan } from '../api';
import { Subscription, DataGrid } from '../components';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { AddCircle, Search as SearchIcon } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const [openArtisanDetails, setOpenArtisanDetails] = useState(false);
  const [checkArtisan, setCheckArtisan] = useState([]);
  const [artisanStatus, setArtisanStatus] = useState(false);

  const [locations, setLocations] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [locationFilter, setLocationFilter] = useState('0');
  const [searchFilter, setSearchFilter] = useState({});
  const [skills, setSkills] = useState();
  const [skillFilter, setSkillFilter] = useState('0');

  const [open, setOpen] = useState(false);
  const [pageMessage, setPageMessage] = useState('');

  const [availableRoles, setAvailableRoles] = useState([]);
  const [artisans, setArtisans] = useState([]);
  const [artisanSearchTerm, setArtisanSearchTerm] = useState('');
  const [filteredArtisans, setFilteredArtisans] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  // const [genderFilter, setGenderFilter] = useState('');

  const handleCloseArtisanDetails = () => setOpenArtisanDetails(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [addPlanDialogOpen, setAddPlanDialogOpen] = useState(false);
  const [pageError, setPageError] = useState('');

  const {
    formState: { errors }
  } = useForm({
    defaultValues: {
      user_type: '',
      subscription_name: ''
    }
  });

  useEffect(() => {
    const getPlans = async () => {
      setLoading(true);
      const response = await fetchAllPlans();

      if (response?.error) {
        setPageError(response.error.message);
      } else {
        setPlans(response.data || []);
      }
      setLoading(false);
    };

    getPlans();
  }, []);

  const handleAddPlanConfirmClose = () => {
    setAddPlanDialogOpen(false);
  };

  const handleAddPlanConfirmOpen = (row) => {
    setAddPlanDialogOpen(true);
  };
  const getRedirectPath = (planId = null) => {
    if (planId) {
      return `/dashboard/subscription/plan?id=${planId}`;
    }

    return '/dashboard/subscription/plan';
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left'
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    minHeight: '30vh',
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    {
      field: 'phone',
      headerName: 'Subscription Modules',
      width: 200
    },
    {
      field: 'email',
      headerName: 'Identifier ID',
      width: 200
    },
    {
      field: 'skill',
      headerName: 'Contact Details',
      description: 'date',
      width: 160
    },
    {
      field: 'assessments',
      headerName: 'Payment Method',
      description: 'date',
      width: 160
    }, {
      field: 'subscription_name',
      headerName: 'Subscription Name',
      description: 'date',
      width: 160
    }, {
      field: 'subscription_balance',
      headerName: 'Subscription Balance',
      description: 'date',
      width: 160
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      description: 'date',
      width: 160
    },
  ];

  const handleassignAplan = () => {
    // Reset states when opening the modal
    setArtisanSearchTerm('');
    setFilteredArtisans([]);
    setSelectedUserId(null);
    setSelectedPlanId(null);
    setSelectedArtisanInfo(null);
    setPageError('');
    setPageMessage('');
    setOpen(true);
  };

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedArtisanInfo, setSelectedArtisanInfo] = useState(null);

  const handleArtisanSelect = (artisan) => {
    setSelectedUserId(artisan.id);
    setSelectedArtisanInfo(artisan);
    setFilteredArtisans([]);
  };

  const searchArtisans = async () => {
    setIsSearching(true);
    setPageError('');

    if (!artisanSearchTerm || artisanSearchTerm.trim() === '') {
      setFilteredArtisans([]);
      setIsSearching(false);
      return;
    }

    try {
      let searchParams = {};
      if (artisanSearchTerm.includes('@')) {
        searchParams = { email: artisanSearchTerm };
      } else {
        searchParams = { name: artisanSearchTerm };
      }

      const response = await fetchAllArtisans(searchParams);

      if (response?.success && response?.data?.data) {
        const formattedArtisans = response.data.data.map(artisan => ({
          id: artisan.id,
          name: `${artisan.first_name} ${artisan.last_name}`,
          email: artisan.email,
          phone: artisan.phone
        }));

        setFilteredArtisans(formattedArtisans);
      } else {
        setFilteredArtisans([]);
      }
    } catch (error) {
      console.error('Error searching artisans:', error);
      setPageError('Failed to search artisans. Please try again.');
    } finally {
      setIsSearching(false);
    }
  };
  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      getAllArtisans(page);
      setSearchFilter({});
    }
    let categorize;
    if (searchTerm.includes('@')) {
      categorize = { type: 'email', value: searchTerm };
    } else if (/^\+?\d+$/.test(searchTerm)) {
      categorize = { type: 'phone', value: searchTerm };
    } else {
      categorize = { type: 'name', value: searchTerm };
    }
    setSearchFilter(categorize);
  };

  const getAllArtisans = async (page) => {
    setLoading(true);
    const response = await fetchAllArtisans({
      page: page + 1,
      ...(locationFilter > 0 ? { locationId: locationFilter } : {}),
      ...(skillFilter > 0 ? { skillId: skillFilter } : {}),
      ...(searchFilter?.type === 'email' ? { email: searchFilter.value } : {}),
      ...(searchFilter?.type === 'name' ? { name: searchFilter.value } : {}),
      ...(searchFilter?.type === 'phone' ? { phone: searchFilter.value } : {}),
    });
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((artisanData) => ({
        ...artisanData,
        name: `${artisanData?.first_name} ${artisanData?.last_name}`,
        assessments:
          artisanData?.competency_assessments?.length > 0 &&
            artisanData?.vetting_report?.length > 0 &&
            artisanData?.upskilling_reports?.length > 0
            ? 'True'
            : 'False',
        skill: artisanData?.profile?.skills?.length
          ? artisanData?.profile?.skills?.map((item) => item?.name)
          : ' _ '
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const getOneRequest = async (id) => {
    setLoading(true);
    const response = await fetchOneArtisan(id);
    setLoading(false);

    if (response?.success && response?.data) {
      setCheckArtisan(response?.data);
      setArtisanStatus(response?.success);
    }
  };

  const handleOpenArtisanDetails = (id) => {
    getOneRequest(id);
    setOpenArtisanDetails(true);
  };

  const getPage = async (page) => {
    await getAllArtisans(page);
    setPage(page);
  };

  const handleExport = async () => {
    setLoading(true);

    const response = await exportUserData('artisan');

    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');

    a.download = `AllArtisans_export_output_${date}.csv`;
    a.href = response?.data?.report;

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    setLoading(false);
  };

  useEffect(() => {
    getAllArtisans(page);
  }, [page, locationFilter, searchFilter, skillFilter]);

  const getAllLocations = async () => {
    const data = await getLocations();
    setLocations(data);
  };

  useEffect(() => {
    getAllLocations();
  }, []);

  const getAllSkills = async () => {
    const data = await getSkills();
    setSkills(data);
  };

  useEffect(() => {
    getAllSkills();
  }, []);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <Grid container justifyContent='space-between'>
        <Grid item lg={6}>
          <Typography variant="h5" mb={3}>
            All Subscriptions
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <Button
            variant="outlined"
            sx={{ width: 'full', float: 'right' }}
            onClick={() => handleExport()}
          >
            {' '}
            Export{' '}
          </Button>

          <Button
            // pb={2}
            variant="outlined"
            sx={{ width: 'full', float: 'right', marginRight: '10px' }}
            onClick={() => handleAddPlanConfirmOpen()}
          >
            Manage Plans
          </Button>
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={3}>
        <Box display="flex" sx={{ flexGrow: 1, gap: 1 }}>
          <form
            style={{ display: 'flex', flexGrow: 1, gap: 1 }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              placeholder='Search by name or email or phone number'
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSearch()}
            >
              Search
            </Button>
          </form>
        </Box>
        {/* <FormControl sx={{ width: 100 }}>
            <InputLabel id="filter-label">Location</InputLabel>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={locationFilter}
              onChange={(e) => setLocationFilter(e.target.value)}
              label="Filter by Location"
            >
              <MenuItem value="0">All</MenuItem>
              {locations?.map((location) => (
                <MenuItem key={location.id} value={location.id.toString()}>{location.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 100 }}>
            <InputLabel id="filter-label">Job Skills</InputLabel>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={skillFilter}
              onChange={(e) => setSkillFilter(e.target.value)}
              label="Filter by Skill"
            >
              <MenuItem value="0">All</MenuItem>
              {skills?.map((skill) => (
                <MenuItem key={skill.id} value={skill.id.toString()}>{skill.name}</MenuItem>
              ))}
            </Select>
          </FormControl> */}
      </Box>

      <Box width="100%">
        <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          onRowClick={(row) => handleOpenArtisanDetails(row?.row?.id)}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[15]}
          paginationMode="server"
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
        {/* <Modal
          open={openArtisanDetails}
          onClose={handleCloseArtisanDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={requiredWidth ? style : mobileStyle}>
            <Subscription artisan={checkArtisan} status={artisanStatus} />
          </Box>
        </Modal> */}
      </Box>
      <Dialog
        open={addPlanDialogOpen}
        onClose={handleAddPlanConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ padding: 3 }}
      >
        <Box sx={{ padding: '20px', width: '100%', overflow: 'hidden' }}>
          <DialogTitle id="alert-dialog-title"><strong>List of Plans</strong></DialogTitle>
          <DialogContent sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <RadioGroup value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
                  {plans.map((plan) => (
                    <FormControlLabel
                      key={plan.id}
                      value={plan.id}
                      control={
                        <Radio
                          icon={<RadioButtonUncheckedIcon sx={{ color: '#FF5318', fontSize: 22 }} />}
                          checkedIcon={<CheckCircleOutlineIcon sx={{ color: '#FF5318', fontSize: 22 }} />}
                        />
                      }
                      label={plan.name}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              component={Link}
              to={getRedirectPath(selectedPlan)}
              variant="contained"
              fullWidth
              disabled={!selectedPlan}
              sx={{
                backgroundColor: selectedPlan ? 'primary.main' : '#d3d3d3',
                color: selectedPlan ? '#fff' : '#888',
                fontWeight: 'bold',
                borderRadius: '8px',
                padding: '12px 0',
              }}
            >
              Update Plan
            </Button>

            <Button
              component={Link}
              to={getRedirectPath()}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: 'primary.main',
                color: '#fff',
                fontWeight: 'bold',
                borderRadius: '8px',
                padding: '12px 0',
              }}
            >
              Add New Plan
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

    </Box>
  );
}

export default Index;
