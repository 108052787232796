import { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  Link as MUILink,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  AccountCircle,
  Logout,
  LocalActivityOutlined,
  DashboardCustomizeOutlined,
  RequestQuoteOutlined,
  Assessment,
  SettingsApplicationsOutlined,
  Receipt,
  SettingsAccessibilityOutlined,
  PeopleOutlineTwoTone
} from '@mui/icons-material';

import { Logo } from '../';
import { fetchAvailableRoles } from '../../api';

// Define sidebar items with their required permissions
const sidebarItems = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <DashboardCustomizeOutlined />,
    // Everyone gets dashboard
    requiredPermissions: []
  },
  {
    title: 'Requests',
    path: 'job-requests',
    icon: <RequestQuoteOutlined />,
    requiredPermissions: ['get job requests', 'get a job request', 'create job request']
  },
  {
    title: 'Customers',
    path: 'all-customers',
    icon: <AccountCircle />,
    requiredPermissions: ['get users', 'get a user', 'create a user']
  },
  {
    title: 'Artisans',
    path: 'artisans',
    icon: <AccountCircle />,
    requiredPermissions: ['get users', 'get a user', 'create a user']
  },
  {
    title: 'Quotations',
    path: 'quotations',
    icon: <LocalActivityOutlined />,
    requiredPermissions: ['get quotations', 'get a quotation', 'create quotation']
  },
  {
    title: 'Invoices',
    path: 'invoices',
    icon: <LocalActivityOutlined />,
    requiredPermissions: ['get invoices', 'get a invoice', 'create invoice']
  },
  {
    title: 'Receipt',
    path: 'receipt',
    icon: <Receipt />,
    requiredPermissions: ['get receipts', 'get a receipt']
  },

  {
    title: 'Assessments',
    path: 'assessments',
    icon: <Assessment />,
    requiredPermissions: ['create competency assessment', 'update competency assessment', 'create upskilling report', 'update upskilling report', 'create vetting report', 'update vetting report']
  },
  {
    title: 'App Management',
    path: 'app-management',
    icon: <SettingsApplicationsOutlined />,
    requiredPermissions: ['create location', 'update location', 'delete location', 'create skill', 'update skill', 'delete skill']
  },
  {
    title: 'Roles',
    path: 'roles-functions',
    icon: <SettingsAccessibilityOutlined />,
    requiredPermissions: ['view roles', 'update role', 'create role', 'delete role']
  },
  {
    title: 'Admin Management',
    path: 'admin-management',
    icon: <PeopleOutlineTwoTone />,
    requiredPermissions: ['add new admin', 'update admin', 'delete admin', 'get admins']
  },
  {
    title: 'Assessor Management',
    path: 'assessor-management',
    icon: <PeopleOutlineTwoTone />,
    // Super-admin only for now, could be refined with more specific permissions
    requiredPermissions: ['add new admin', 'view roles']
  }
];

export default function Sidebar() {
  const navLinkStyle = ({ isActive }) => ({
    color: isActive ? 'primary' : 'inherit',
    fontWeight: isActive ? 'bold' : 'normal'
  });

  const [rolesData, setRolesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchRoles = async () => {
    try {
      setIsLoading(true);
      const data = await fetchAvailableRoles();
      setRolesData(data?.data?.roles || []);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const logout = () => {
    window.localStorage.clear();
    window.location.reload('/');
  };

  const userRoleRaw = localStorage.getItem('user-role') || '""';
  const userRole = userRoleRaw.replace(/"/g, '');

  const userRoleData = rolesData.find(role => role.name === userRole);

  const userPermissions = userRoleData?.permisions?.map(perm => perm.name) || [];

  const getVisibleSidebarItems = () => {
    return sidebarItems.filter(item => {
      // If no required permissions, show to everyone
      if (item.requiredPermissions.length === 0) {
        return true;
      }

      const permission = item.requiredPermissions.some(permission =>
        userPermissions.includes(permission)
      );
      return permission;
    });
  };

  const visibleItems = getVisibleSidebarItems();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'fixed',
        minHeight: '100vh',
        top: 0,
        left: 0,
        bottom: 0,
        minWidth: { md: '25vw', lg: '20vw', xl: '15vw' },
        width: '250px',
        background: '#FAFAFA',
        border: '1px solid #FAFAFA',
        overflowY: 'scroll'
      }}
    >
      <List sx={{ width: '100%', minHeight: '100vh' }}>
        <ListItem>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Logo />
          </Box>
        </ListItem>

        {isLoading ? (
          <ListItem>
            <ListItemText primary="Loading menu..." />
          </ListItem>
        ) : (
          <>
            {/* Map through the visible sidebar items */}
            {visibleItems.map((item, index) => (
              <ListItem key={index}>
                <ListItemButton>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <MUILink
                    underline="none"
                    component={NavLink}
                    to={item.path}
                    color="inherit"
                    style={navLinkStyle}
                  >
                    <ListItemText primary={item.title} />
                  </MUILink>
                </ListItemButton>
              </ListItem>
            ))}
          </>
        )}

        {/* Logout option (shown to everyone) */}
        <ListItem py={1}>
          <ListItemButton>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <MUILink
              underline="none"
              component={NavLink}
              to=""
              onClick={() => {
                logout();
              }}
              color="inherit"
              style={navLinkStyle}
            >
              <ListItemText primary="Logout" />
            </MUILink>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}
