import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Link as MUILink,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  AccountCircle,
  Logout,
  DashboardCustomizeOutlined,
  RequestQuoteOutlined,
  Assessment,
  SettingsApplicationsOutlined,
  SettingsAccessibilityOutlined,
  LocalActivityOutlined,
  PeopleOutlineTwoTone
} from '@mui/icons-material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { SideBar } from '../';
import LogoImage from '../../assets/images/fundis-logo.png';
import { fetchAvailableRoles } from '../../api';

// Define menu items with their required permissions (same as sidebar)
const menuItems = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <DashboardCustomizeOutlined />,
    // Everyone gets dashboard
    requiredPermissions: []
  },
  {
    title: 'Requests',
    path: 'job-requests',
    icon: <RequestQuoteOutlined />,
    requiredPermissions: ['get job requests', 'get a job request', 'create job request']
  },
  {
    title: 'Customers',
    path: 'all-customers',
    icon: <AccountCircle />,
    requiredPermissions: ['get users', 'get a user', 'create a user']
  },
  {
    title: 'Artisans',
    path: 'artisans',
    icon: <AccountCircle />,
    requiredPermissions: ['get users', 'get a user', 'create a user']
  },
  {
    title: 'Quotations',
    path: 'quotations',
    icon: <LocalActivityOutlined />,
    requiredPermissions: ['get quotations', 'get a quotation', 'create quotation']
  },
  {
    title: 'Invoices',
    path: 'invoices',
    icon: <LocalActivityOutlined />,
    requiredPermissions: ['get invoices', 'get a invoice', 'create invoice']
  },
  {
    title: 'Receipt',
    path: 'receipt',
    icon: <ReceiptIcon />,
    requiredPermissions: ['get receipts', 'get a receipt']
  },
  {
    title: 'Assessments',
    path: 'assessments',
    icon: <Assessment />,
    requiredPermissions: ['create competency assessment', 'update competency assessment', 'create upskilling report', 'update upskilling report', 'create vetting report', 'update vetting report']
  },
  {
    title: 'App Management',
    path: 'app-management',
    icon: <SettingsApplicationsOutlined />,
    requiredPermissions: ['create location', 'update location', 'delete location', 'create skill', 'update skill', 'delete skill']
  },
  {
    title: 'Roles',
    path: 'roles-functions',
    icon: <SettingsAccessibilityOutlined />,
    requiredPermissions: ['view roles', 'update role', 'create role', 'delete role']
  },
  {
    title: 'Admin Management',
    path: 'admin-management',
    icon: <PeopleOutlineTwoTone />,
    requiredPermissions: ['add new admin', 'update admin', 'delete admin', 'get admins']
  },
  {
    title: 'Assessor Management',
    path: 'assessor-management',
    icon: <PeopleOutlineTwoTone />,
    // Super-admin only for now, could be refined with more specific permissions
    requiredPermissions: ['add new admin', 'view roles']
  }
];
function MenuBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [rolesData, setRolesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const navLinkStyle = ({ isActive }) => ({
    color: isActive ? '#FF5318' : 'inherit',
    fontWeight: isActive ? 'bold' : 'normal',
  });

  const userRoleRaw = localStorage.getItem('user-role') || '""';
  const userRole = userRoleRaw.replace(/"/g, '');

  const fetchRoles = async () => {
    try {
      setIsLoading(true);
      const data = await fetchAvailableRoles();
      setRolesData(data?.data?.roles || []);
    } catch (error) {
      console.error('Error fetching roles:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const userRoleData = rolesData.find(role => role.name === userRole);
  const userPermissions = userRoleData?.permisions?.map(perm => perm.name) || [];

  const getVisibleMenuItems = () => {
    return menuItems.filter(item => {
      // If no required permissions, show to everyone
      if (item.requiredPermissions.length === 0) {
        return true;
      }

      // Check if user has at least one of the required permissions
      return item.requiredPermissions.some(permission =>
        userPermissions.includes(permission)
      );
    });
  };

  const visibleMenuItems = getVisibleMenuItems();

  const logout = () => {
    window.localStorage.clear();
    window.location.reload('/');
  };

  return (
    <>
      {!largeScreen && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: 'background.default',
          }}
          paddingLeft={1}
          paddingY={1}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box component="img" src={LogoImage} alt="Fundis Logo" />
            <Typography variant="h6" component="span" fontWeight="bold">
              FUNDIS
            </Typography>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              top: '0.75rem',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: {
                minWidth: { xs: '100vw', sm: '300px' },
                boxShadow: 'none',
                borderRadius: '0px',
              },
            }}
          >
            {isLoading ? (
              <MenuItem>
                <Typography>Loading menu...</Typography>
              </MenuItem>
            ) : (
              <>
                {/* Map through visible menu items based on permissions */}
                {visibleMenuItems.map((item, index) => (
                  <MenuItem key={index}>
                    <MUILink
                      underline="none"
                      component={NavLink}
                      to={item.path}
                      color="inherit"
                      style={navLinkStyle}
                      sx={item.isSubItem ? { pl: 4 } : {}}
                    >
                      <IconButton>
                        {item.icon}
                      </IconButton>
                      {item.title}
                    </MUILink>
                  </MenuItem>
                ))}

                {/* Logout option (shown to everyone) */}
                <MenuItem>
                  <MUILink
                    underline="none"
                    component={NavLink}
                    to=""
                    onClick={() => {
                      logout();
                    }}
                    color="inherit"
                    style={navLinkStyle}
                  >
                    <IconButton>
                      <Logout />
                    </IconButton>
                    Logout
                  </MUILink>
                </MenuItem>
              </>
            )}
          </Menu>
        </Box>
      )}
      {largeScreen && <SideBar />}
    </>
  );
}

export default MenuBar;
