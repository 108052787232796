/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Modal,
  Grid,
  Button,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  FormControl,
  CardContent,
  Card,
  Alert,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
} from '@mui/material';
import {
  fetchAllArtisans,
  fetchOneArtisan,
  exportUserData,
  getLocations,
  getRequestTypes as getSkills,
  deactivateOneArtisan,
  activateOneArtisan,
  deleteOneArtisan,
} from '../api';
import { Artisan, DataGrid } from '../components';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useForm } from 'react-hook-form';
import {
  createAdmin,
  createUser,
  fetchAvailableRoles,
} from '../api/roleManagement';
import { assignPlan, fetchAllPlans } from '../api/customers';
import { AddCircle, Search as SearchIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const [openArtisanDetails, setOpenArtisanDetails] = useState(false);
  const [checkArtisan, setCheckArtisan] = useState([]);
  const [artisanStatus, setArtisanStatus] = useState(false);

  const [locations, setLocations] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [locationFilter, setLocationFilter] = useState('0');
  const [searchFilter, setSearchFilter] = useState({});
  const [skills, setSkills] = useState();
  const [skillFilter, setSkillFilter] = useState('0');

  const [open, setOpen] = useState(false);
  const [pageError, setPageError] = useState('');
  const [pageMessage, setPageMessage] = useState('');

  const [availableRoles, setAvailableRoles] = useState([]);

  const [plans, setPlans] = useState([]);
  const [artisans, setArtisans] = useState([]);
  const [artisanSearchTerm, setArtisanSearchTerm] = useState('');
  const [filteredArtisans, setFilteredArtisans] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const handleCloseArtisanDetails = () => setOpenArtisanDetails(false);

  const {
    formState: { errors },
  } = useForm({
    defaultValues: {
      user_type: '',
      subscription_name: '',
      start_date: '',
    },
  });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const deactivateArtisan = async (id) => {
    setLoading(true);
    const response = await deactivateOneArtisan(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('Artisan account has been deactivated.');
      getAllArtisans(page); // Refresh the data instead of reloading the page
    } else {
      setPageError('There is a problem with this account');
    }
  };

  const activateArtisan = async (id) => {
    setLoading(true);
    const response = await activateOneArtisan(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('Artisan account has been reactivated.');
      getAllArtisans(page); // Refresh the data instead of reloading the page
    } else {
      setPageError('You are unable to activate this account at the moment.');
    }
  };

  const deleteArtisan = async (id) => {
    setLoading(true);
    const response = await deleteOneArtisan(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('Artisan account has been DELETED.');
      getAllArtisans(page); // Refresh the data instead of reloading the page
    } else {
      setPageError('You are unable to delete this account at the moment.');
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left',
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    minHeight: '30vh',
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'skill',
      headerName: 'Skill',
      description: 'date',
      width: 160,
    },
    {
      field: 'assessments',
      headerName: 'Assessments',
      description: 'date',
      width: 160,
    },
    {
      field: 'subscription_name',
      headerName: 'Subscription Name',
      description: 'date',
      width: 160,
    },
    {
      field: 'subscription_balance',
      headerName: 'Subscription Balance',
      description: 'date',
      width: 160,
    },
    // {
    //   field: 'start_date',
    //   headerName: 'Start Date',
    //   description: 'date',
    //   width: 160
    // },
    {
      field: 'action',
      headerName: 'Deactivate account',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const onClickDeactivate = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          await deactivateArtisan(params?.id);
        };

        const onClickActivate = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          await activateArtisan(params?.id);
        };

        if (params?.row?.is_active === true) {
          return <Button onClick={onClickDeactivate}>Deactivate</Button>;
        } else {
          return <Button onClick={onClickActivate}>Activate</Button>;
        }
      },
    },
    {
      field: 'delete',
      headerName: 'Delete account',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const onClickDelete = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          await deleteArtisan(params?.id);
          setDeletingId(null);
        };

        return (
          <div>
            <Button
              onClick={(e) => {
                e.stopPropagation(); // Prevent row selection
                setDeletingId(params.id);
              }}
            >
              Delete account
            </Button>
            <Dialog
              open={deletingId === params.id}
              onClose={() => setDeletingId(null)}
              aria-labelledby="confirm-dialog"
            >
              <DialogTitle>Delete Artisan</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this artisan account?
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => setDeletingId(null)}
                  color="secondary"
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={onClickDelete}
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      },
    },
  ];

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      getAllArtisans(page);
      setSearchFilter({});
    }
    let categorize;
    if (searchTerm.includes('@')) {
      categorize = { type: 'email', value: searchTerm };
    } else if (/^\+?\d+$/.test(searchTerm)) {
      categorize = { type: 'phone', value: searchTerm };
    } else {
      categorize = { type: 'name', value: searchTerm };
    }
    setSearchFilter(categorize);
  };

  const searchArtisans = async () => {
    setIsSearching(true);
    setPageError('');

    if (!artisanSearchTerm || artisanSearchTerm.trim() === '') {
      setFilteredArtisans([]);
      setIsSearching(false);
      return;
    }

    try {
      let searchParams = {};
      if (artisanSearchTerm.includes('@')) {
        searchParams = { email: artisanSearchTerm };
      } else {
        searchParams = { name: artisanSearchTerm };
      }

      const response = await fetchAllArtisans(searchParams);

      if (response?.success && response?.data?.data) {
        const formattedArtisans = response.data.data.map((artisan) => ({
          id: artisan.id,
          name: `${artisan.first_name} ${artisan.last_name}`,
          email: artisan.email,
          phone: artisan.phone,
        }));

        setFilteredArtisans(formattedArtisans);
      } else {
        setFilteredArtisans([]);
      }
    } catch (error) {
      console.error('Error searching artisans:', error);
      setPageError('Failed to search artisans. Please try again.');
    } finally {
      setIsSearching(false);
    }
  };

  const getAllArtisans = async (page) => {
    setLoading(true);
    const response = await fetchAllArtisans({
      page: page + 1,
      ...(locationFilter > 0 ? { locationId: locationFilter } : {}),
      ...(skillFilter > 0 ? { skillId: skillFilter } : {}),
      ...(searchFilter?.type === 'email' ? { email: searchFilter.value } : {}),
      ...(searchFilter?.type === 'name' ? { name: searchFilter.value } : {}),
      ...(searchFilter?.type === 'phone' ? { phone: searchFilter.value } : {}),
    });
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((artisanData) => ({
        ...artisanData,
        name: `${artisanData?.first_name} ${artisanData?.last_name}`,
        assessments:
          artisanData?.competency_assessments?.length > 0 &&
          artisanData?.vetting_report?.length > 0 &&
          artisanData?.upskilling_reports?.length > 0
            ? 'True'
            : 'False',
        skill: artisanData?.profile?.skills?.length
          ? artisanData?.profile?.skills?.map((item) => item?.name)
          : ' _ ',
        subscriptionName: artisanData?.subscription_name || 'N/A',
        subscriptionBalance: artisanData?.subscription_balance || 'N/A',
        startDate: artisanData?.start_date || 'N/A',
      }));
      console.log(response?.data?.data);
      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const getOneRequest = async (id) => {
    setLoading(true);
    const response = await fetchOneArtisan(id);
    setLoading(false);

    if (response?.success && response?.data) {
      setCheckArtisan(response?.data);
      setArtisanStatus(response?.success);
    }
  };

  const handleOpenArtisanDetails = (id) => {
    getOneRequest(id);
    setOpenArtisanDetails(true);
  };

  const getPage = async (page) => {
    await getAllArtisans(page);
    setPage(page);
  };

  const handleExport = async () => {
    setLoading(true);

    const response = await exportUserData('artisan');

    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');

    a.download = `AllArtisans_export_output_${date}.csv`;
    a.href = response?.data?.report;

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    setLoading(false);
  };

  useEffect(() => {
    getAllArtisans(page);
  }, [page, locationFilter, searchFilter, skillFilter]);

  useEffect(() => {
    fetchAvailableRoles().then((data) => {
      setAvailableRoles(data?.data?.roles);
    });
  }, []);

  const getAllLocations = async () => {
    const data = await getLocations();
    setLocations(data);
  };

  useEffect(() => {
    getAllLocations();
  }, []);

  const getAllSkills = async () => {
    const data = await getSkills();
    setSkills(data);
  };

  useEffect(() => {
    getAllSkills();
  }, []);

  useEffect(() => {
    const getPlans = async () => {
      setLoading(true);
      const response = await fetchAllPlans();

      if (response?.error) {
        setPageError(response.error.message);
      } else {
        setPlans(response.data || []);
      }
      setLoading(false);
    };

    getPlans();
  }, []);

  useEffect(() => {
    const getArtisans = async () => {
      const response = await fetchAllArtisans();
      if (response?.error) {
        setPageError(response.error.message);
      } else {
        setArtisans(response?.data?.data || []);
      }
    };

    getArtisans();
  }, []);

  const userTypeOptions = ['artisan'];
  const requiredWidth = useMediaQuery('(min-width:1024px)');

  const userType = localStorage.getItem('user-role');

  const handleassignAplan = () => {
    // Reset states when opening the modal
    setArtisanSearchTerm('');
    setFilteredArtisans([]);
    setSelectedUserId(null);
    setSelectedPlanId(null);
    setSelectedArtisanInfo(null);
    setStartDate(dayjs().format('YYYY-MM-DD')); // Reset start date to today
    setPageError('');
    setPageMessage('');
    setOpen(true);
  };

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedArtisanInfo, setSelectedArtisanInfo] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [addPlanDialogOpen, setAddPlanDialogOpen] = useState(false);

  const handleArtisanSelect = (artisan) => {
    setSelectedUserId(artisan.id);
    setSelectedArtisanInfo(artisan);
    setFilteredArtisans([]);
  };

  const handleSubmitAssign = async (event) => {
    event.preventDefault();

    if (!selectedUserId) {
      setPageError('Please select an artisan.');
      return; // Add return statement to prevent further execution
    }

    if (!selectedPlanId) {
      setPageError('Please select a plan.');
      return; // Add return statement to prevent further execution
    }

    try {
      setLoading(true);
      // Use the selected start date instead of today's date
      await assignPlan({
        user_id: selectedUserId,
        plan_id: selectedPlanId,
        start_date: startDate,
      });
      setPageMessage(
        `Successfully assigned plan to ${
          selectedArtisanInfo?.name || 'artisan'
        }`
      );
      setLoading(false);

      setTimeout(() => {
        setOpen(false);
        getAllArtisans(page);
      }, 2000);
    } catch (error) {
      setLoading(false);
      setPageError('Failed to assign plan. Please try again.');
      console.error('Error assigning plan:', error);
    }
  };

  const handleAddPlanConfirmClose = () => {
    setAddPlanDialogOpen(false);
  };

  const handleAddPlanConfirmOpen = (row) => {
    setAddPlanDialogOpen(true);
  };
  const getRedirectPath = (planId = null) => {
    if (planId) {
      return `/dashboard/subscription/plan?id=${planId}`;
    }

    return '/dashboard/subscription/plan';
  };

  useEffect(() => {
    const getPlans = async () => {
      setLoading(true);
      const response = await fetchAllPlans();

      if (response?.error) {
        setPageError(response.error.message);
      } else {
        setPlans(response.data || []);
      }
      setLoading(false);
    };

    getPlans();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={4}
      >
        <Button
          variant="outlined"
          fontWeight="bold"
          onClick={() => handleassignAplan()}
          // disabled={userType !== '"super-admin"'}
        >
          <AddCircle /> Assign Plan to Artisan
        </Button>

        <Button
          // pb={2}
          variant="outlined"
          sx={{ width: 'full', float: 'right', marginRight: '10px' }}
          onClick={() => handleAddPlanConfirmOpen()}
        >
          Manage Plans
        </Button>
      </Box>

      <Grid container justifyContent="space-between">
        <Grid item lg={6}>
          <Typography variant="h5" mb={3}>
            All Artisans
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <Button
            variant="outlined"
            sx={{ width: 300, float: 'right' }}
            onClick={() => handleExport()}
          >
            {' '}
            Export{' '}
          </Button>
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={3}
      >
        <Box display="flex" sx={{ flexGrow: 1, gap: 1 }}>
          <form
            style={{ display: 'flex', flexGrow: 1, gap: 1 }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              placeholder="Search by name or email or phone number"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSearch()}
            >
              Search
            </Button>
          </form>
        </Box>
        <FormControl sx={{ width: 100 }}>
          <InputLabel id="filter-label">Location</InputLabel>
          <Select
            labelId="filter-label"
            id="filter-select"
            value={locationFilter}
            onChange={(e) => setLocationFilter(e.target.value)}
            label="Filter by Location"
          >
            <MenuItem value="0">All</MenuItem>
            {locations?.map((location) => (
              <MenuItem key={location.id} value={location.id.toString()}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: 100 }}>
          <InputLabel id="filter-label">Job Skills</InputLabel>
          <Select
            labelId="filter-label"
            id="filter-select"
            value={skillFilter}
            onChange={(e) => setSkillFilter(e.target.value)}
            label="Filter by Skill"
          >
            <MenuItem value="0">All</MenuItem>
            {skills?.map((skill) => (
              <MenuItem key={skill.id} value={skill.id.toString()}>
                {skill.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box width="100%">
        <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          onRowClick={(row) => handleOpenArtisanDetails(row?.row?.id)}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[15]}
          paginationMode="server"
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
        <Modal
          open={openArtisanDetails}
          onClose={handleCloseArtisanDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={requiredWidth ? style : mobileStyle}>
            <Artisan artisan={checkArtisan} status={artisanStatus} />
          </Box>
        </Modal>
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { lg: 600, md: 300 }, padding: 'auto' }}>
          {pageError && <Alert severity="error">{pageError}</Alert>}
          {pageMessage && <Alert severity="success">{pageMessage}</Alert>}
          <Grid mb={1} container justifyContent="center">
            <Typography
              id="modal-modal-title"
              variant="h6"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Assign Plan to Artisan
            </Typography>
          </Grid>
          <form onSubmit={handleSubmitAssign}>
            <CardContent sx={{ padding: 5 }}>
              <Grid container>
                {/* Artisan search field */}
                <Grid item xs={12} p={2}>
                  <Box sx={{ position: 'relative' }}>
                    <TextField
                      variant="outlined"
                      label="Search Artisan by Name or Email"
                      fullWidth
                      value={artisanSearchTerm}
                      onChange={(e) => setArtisanSearchTerm(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <Button
                            onClick={searchArtisans}
                            disabled={isSearching}
                            sx={{ position: 'absolute', right: 0 }}
                          >
                            <SearchIcon />
                          </Button>
                        ),
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          searchArtisans();
                        }
                      }}
                    />

                    {/* Selected artisan display */}
                    {selectedArtisanInfo && (
                      <Box
                        sx={{
                          mt: 2,
                          p: 2,
                          border: '1px solid #e0e0e0',
                          borderRadius: 1,
                        }}
                      >
                        <Typography variant="subtitle1" fontWeight="bold">
                          Selected Artisan:
                        </Typography>
                        <Typography>{selectedArtisanInfo.name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {selectedArtisanInfo.email} |{' '}
                          {selectedArtisanInfo.phone || 'No phone'}
                        </Typography>
                      </Box>
                    )}

                    {/* Search results */}
                    {filteredArtisans.length > 0 && (
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '100%',
                          maxHeight: '200px',
                          overflowY: 'auto',
                          zIndex: 1000,
                          backgroundColor: 'white',
                          boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
                          borderRadius: '4px',
                          mt: 1,
                        }}
                      >
                        {filteredArtisans.map((artisan) => (
                          <Box
                            key={artisan.id}
                            sx={{
                              p: 2,
                              borderBottom: '1px solid #e0e0e0',
                              '&:hover': {
                                backgroundColor: '#f5f5f5',
                                cursor: 'pointer',
                              },
                            }}
                            onClick={() => handleArtisanSelect(artisan)}
                          >
                            <Typography variant="body1">
                              {artisan.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {artisan.email}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Grid>

                {/* Plan Dropdown */}
                <Grid item xs={12} p={2}>
                  <TextField
                    variant="outlined"
                    label="Select Plan"
                    select
                    onChange={(e) => setSelectedPlanId(e.target.value)}
                    fullWidth
                    value={selectedPlanId || ''}
                    error={!!errors.subscription_name}
                  >
                    <MenuItem value="">Select a plan</MenuItem>
                    {plans.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  {errors.subscription_name && (
                    <Typography color="error">
                      {errors.subscription_name.message}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} p={2}>
                  <TextField
                    variant="outlined"
                    label="Start Date"
                    type="date"
                    fullWidth
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!errors.start_date}
                  />
                  {errors.start_date && (
                    <Typography color="error">
                      {errors.start_date.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <Grid container justifyContent="center" mt={1} mb={3}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ width: 300 }}
                disabled={loading || !selectedUserId || !selectedPlanId}
              >
                {loading ? 'Processing...' : 'Assign Plan'}
              </Button>
            </Grid>
          </form>
        </Card>
      </Modal>

      <Dialog
        open={addPlanDialogOpen}
        onClose={handleAddPlanConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ padding: 3 }}
      >
        <Box sx={{ padding: '20px', width: '100%', overflow: 'hidden' }}>
          <DialogTitle id="alert-dialog-title">
            <strong>List of Plans</strong>
          </DialogTitle>
          <DialogContent sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <RadioGroup
                  value={selectedPlan}
                  onChange={(e) => setSelectedPlan(e.target.value)}
                >
                  {plans.map((plan) => (
                    <FormControlLabel
                      key={plan.id}
                      value={plan.id}
                      control={
                        <Radio
                          icon={
                            <RadioButtonUncheckedIcon
                              sx={{ color: '#FF5318', fontSize: 22 }}
                            />
                          }
                          checkedIcon={
                            <CheckCircleOutlineIcon
                              sx={{ color: '#FF5318', fontSize: 22 }}
                            />
                          }
                        />
                      }
                      label={plan.name}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              component={Link}
              to={getRedirectPath(selectedPlan)}
              variant="contained"
              fullWidth
              disabled={!selectedPlan}
              sx={{
                backgroundColor: selectedPlan ? 'primary.main' : '#d3d3d3',
                color: selectedPlan ? '#fff' : '#888',
                fontWeight: 'bold',
                borderRadius: '8px',
                padding: '12px 0',
              }}
            >
              Update Plan
            </Button>
            <Button
              component={Link}
              to={getRedirectPath()}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: 'primary.main',
                color: '#fff',
                fontWeight: 'bold',
                borderRadius: '8px',
                padding: '12px 0',
                mr: 4,
              }}
            >
              Add New Plan
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default Index;
