import { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  TablePagination,
  TableFooter,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import ViewRoleModal from '../Roles/ViewRoleModal';
import EditRoleModal from '../Roles/EditRoleModal';
import PropTypes from 'prop-types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(255, 83, 24, 0.08)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const RolesTable = ({ roles = [], onEdit, onDelete, permissionsList }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEditRole, setSelectedEditRole] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleView = (role) => {
    setSelectedRole(role);
    setViewModalOpen(true);
  };

  const handleEditClick = (role) => {
    setSelectedEditRole(role);
    setEditModalOpen(true);
  };

  const handleEditSubmit = async (updatedRole) => {
    await onEdit(updatedRole);
    setEditModalOpen(false);
    setSelectedEditRole(null);
  };

  const handleDeleteClick = (role) => {
    setRoleToDelete(role);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (roleToDelete) {
      await onDelete(roleToDelete);
      setDeleteDialogOpen(false);
      setRoleToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setRoleToDelete(null);
  };

  const emptyRows = page > 0
    ? Math.max(0, (1 + page) * rowsPerPage - roles.length)
    : 0;

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Name
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Guard Name
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Permissions
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1" fontWeight="bold">
                  Actions
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : roles
            ).map((role) => (
              <StyledTableRow key={role.id}>
                <StyledTableCell component="th" scope="row">
                  {role.name}
                </StyledTableCell>
                <StyledTableCell>{role.guard_name}</StyledTableCell>
                <StyledTableCell sx={{ maxWidth: 400 }}>
                  <Typography noWrap>
                    {role?.permisions?.map(p => p.name).join(', ')}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Tooltip title="View">
                    <IconButton
                      onClick={() => handleView(role)}
                      sx={{ '&:hover': { color: 'primary.main' } }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => handleEditClick(role)}
                      sx={{ '&:hover': { color: 'primary.main' } }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => handleDeleteClick(role)}
                      sx={{ '&:hover': { color: 'error.main' } }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                colSpan={4}
                count={roles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <ViewRoleModal
        open={viewModalOpen}
        onClose={() => {
          setViewModalOpen(false);
          setSelectedRole(null);
        }}
        role={selectedRole}
      />

      <EditRoleModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setSelectedEditRole(null);
        }}
        role={selectedEditRole}
        permissions={permissionsList}
        onSubmit={handleEditSubmit}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Delete Role
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {`Are you sure you want to delete the role ${roleToDelete?.name}? This action cannot be undone.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RolesTable.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      guard_name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          name: PropTypes.string.isRequired
        })
      )
    })
  ),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  permissionsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

RolesTable.defaultProps = {
  roles: []
};

export default RolesTable;
