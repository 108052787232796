import axios from 'axios';

export async function createJobRequest(jobRequestData) {
  try {
    const response = await axios.post('/admin/job-request', jobRequestData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error creating job request:', error.response?.data || error.message);
    return error.response?.data || { success: false, error: 'Unknown error' };
  }
}

export async function getJobRequests() {
  try {
    const response = await axios({
      method: '',
      url: '/request-types'
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllJobRequests({ page = 1, name = '', locationId = '', status = '', reference = '', skillId = '', serviceType = '' }) {
  let url = `/admin/job-request?page=${page}`;
  if (locationId) url += `&location_id=${locationId}`;
  if (name) url += `&name=${name}`;
  if (status) url += `&status=${status}`;
  if (reference) url += `&reference=${reference}`;
  if (skillId) url += `&skill_id=${skillId}`;
  if (serviceType) url += `&service_type=${serviceType}`;

  try {
    const response = await axios({
      method: '',
      url
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchOneJobRequest(id) {
  try {
    const response = await axios({
      method: '',
      url: `/admin/job-request/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchJobRequestPagination(url) {
  try {
    const response = await axios({
      method: '',
      url
    });
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchJobRequest(userId) {
  try {
    const response = await axios({
      method: '',
      url: `/customer/job-request/${userId}`
    });
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getRequestTypes() {
  try {
    const response = await axios('/request-types');
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getLocations() {
  try {
    const response = await axios('/locations');
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
}
