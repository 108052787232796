/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Typography, Box, Modal, Grid, Button, InputLabel, Select, TextField, MenuItem, FormControl, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import moment from 'moment';
import { DataGrid, Loader } from '../components';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { fetchAllJobRequests, exportAdminData, fetchAllArtisans, getRequestTypes as getSkills } from '../api';
import { fetchOneJobRequest, getLocations } from '../api/jobRequest';
import SingleJobDetail from '../components/Dashboard/SingleJobDetail';
import useMediaQuery from '@mui/material/useMediaQuery';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const [openJobDetails, setOpenJobDetails] = useState(false);
  const [checkJob, setCheckJob] = useState([]);
  const [availableArtisans, setAvailableArtisans] = useState();
  const [locations, setLocations] = useState();
  const [skills, setSkills] = useState();

  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('0');
  const [serviceTypeFilter, setServiceTypeFilter] = useState('');
  const [skillFilter, setSkillFilter] = useState('0');
  const [searchFilter, setSearchFilter] = useState('');

  const [addJobDialogOpen, setAddJobDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const getRedirectPath = () => {
    if (!selectedOption) return '/dashboard/job-requests/create-a-job-request'; // Default path
    return `/dashboard/job-requests/create-a-job-request?type=${selectedOption}`;
  };

  const handleAddJobConfirmClose = () => {
    setAddJobDialogOpen(false);
  };

  const handleAddJobConfirmOpen = (row) => {
    setAddJobDialogOpen(true);
  };

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      getJobRequests(page);
      setSearchFilter('');
    } else {
      setSearchFilter(searchTerm);
    }
  };

  const handleCloseJobDetails = () => setOpenJobDetails(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left'
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    minHeight: '30vh',
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2
  };

  const navigate = useNavigate();

  const columns = [
    { field: 'job_key', headerName: 'Job ID', width: 200 },
    { field: 'title', headerName: 'Job Title', width: 160 },
    {
      field: 'status',
      headerName: 'Status',
      width: 120
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 160
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200
    },
    {
      field: 'date',
      headerName: 'Date Created',
      description: 'date',
      width: 160
    }
  ];

  const fetchArtisans = async () => {
    let allArtisans = [];
    setLoading(true);
    const res = await fetchAllArtisans(1);
    let page = 1;
    while (page <= res?.data?.last_page) {
      const res = await fetchAllArtisans(page);
      page++;
      allArtisans = allArtisans.concat(res?.data?.data);
    }
    setAvailableArtisans(allArtisans);
    setLoading(false);
  };

  const getJobRequests = async (page) => {
    setLoading(true);
    const response = await fetchAllJobRequests({
      page: page + 1,
      ...(locationFilter > 0 ? { locationId: locationFilter } : {}),
      ...(skillFilter > 0 ? { skillId: skillFilter } : {}),
      ...(serviceTypeFilter ? { serviceType: serviceTypeFilter } : {}),
      ...(statusFilter ? { status: statusFilter } : {}),
      ...(searchTerm ? { reference: searchTerm } : {})
    });

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((requestData) => ({
        ...requestData,
        location: requestData?.location?.name,
        title: requestData?.request_type?.name,
        date: moment(requestData?.created_at).format('ll')
      }));
      setLoading(false);
      setRowData(rows);
      setRowCount(data?.total);
    } else {
      const response = await fetchAllJobRequests({ page });
      const { data } = response;
      const rows = data?.data.map((requestData) => ({
        ...requestData,
        location: requestData?.location?.name,
        title: requestData?.request_type?.name,
        date: moment(requestData?.created_at).format('ll')
      }));
      setLoading(false);
      setRowData(rows);
      setRowCount(data?.total);
    }
    setLoading(false);
  };
  const getPage = async (page) => {
    await getJobRequests(page);
    setPage(page);
  };

  const getJobDetails = (row) => {
    navigate(`${row?.id}`);
  };

  const getOneRequest = async (id) => {
    setLoading(true);
    const response = await fetchOneJobRequest(id);
    setLoading(false);

    if (response?.success && response?.data) {
      setCheckJob(response?.data);
    }
  };

  const handleOpenJobDetails = async (id) => {
    await getOneRequest(id);

    setOpenJobDetails(true);
  };

  const handleExport = async () => {
    setLoading(true);

    const response = await exportAdminData('job-request');

    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');

    a.download = `AllJobRequest_export_output_${date}.csv`;
    a.href = response?.data?.report;

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    setLoading(false);
  };

  const getAllLocations = async () => {
    const data = await getLocations();
    setLocations(data);
  };

  const getAllSkills = async () => {
    const data = await getSkills();
    setSkills(data);
  };

  useEffect(() => {
    getJobRequests(page);
  }, [page, locationFilter, statusFilter, searchFilter, serviceTypeFilter, skillFilter]);

  useEffect(() => {
    getAllLocations();
  }, []);

  useEffect(() => {
    getAllSkills();
  }, []);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  return (
    <>
      {loading && <Loader />}
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={{ lg: 'lg', xl: 'lg' }}
        p={5}
        minHeight="100vh"
        container
        flexGrow={1}
      >
        {/* <ToastContainer/> */}
        <Grid container justifyContent='space-between'>
          <Grid item lg={6}>
            <Typography variant="h5" mb={3}>
              All Job Requests
            </Typography>
          </Grid>
          <Grid item lg={6} sm={4} md={4}>
            <Button
              // pb={2}
              variant="outlined"
              sx={{ width: 100, float: 'right' }}
              onClick={() => handleExport()}
            >
              Export
            </Button>
            <Button
              // pb={2}
              variant="outlined"
              sx={{ width: 'full', float: 'right', marginRight: '10px' }}
              onClick={() => handleAddJobConfirmOpen()}
            >
              Create new request
            </Button>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={3}>
          <Box display="flex" sx={{ flexGrow: 1, gap: 1 }}>
            <form
              style={{ display: 'flex', flexGrow: 1, gap: 1 }}
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
              }}
            >
              <TextField
                label="Search"
                variant="outlined"
                fullWidth
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSearch()}
              >
                Search
              </Button>
            </form>
          </Box>
          <FormControl sx={{ width: 100 }}>
            <InputLabel id="filter-label">Location</InputLabel>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={locationFilter}
              onChange={(e) => setLocationFilter(e.target.value)}
              label="Filter by Location"
            >
              <MenuItem value="0">All</MenuItem>
              {locations?.map((location) => (
                <MenuItem key={location.id} value={location.id.toString()}>{location.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 100 }}>
            <InputLabel id="filter-label">Status</InputLabel>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              label="Filter by Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
              <MenuItem value="cancel">Cancelled</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" alignItems="center" gap={2} mb={3}>
          <FormControl sx={{ width: 100 }}>
            <InputLabel id="filter-label">Job Skills</InputLabel>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={skillFilter}
              onChange={(e) => setSkillFilter(e.target.value)}
              label="Filter by Skill"
            >
              <MenuItem value="0">All</MenuItem>
              {skills?.map((skill) => (
                <MenuItem key={skill.id} value={skill.id.toString()}>{skill.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: 100 }}>
            <InputLabel id="filter-label">Service type</InputLabel>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={serviceTypeFilter}
              onChange={(e) => setServiceTypeFilter(e.target.value)}
              label="Filter by Service Type"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="business">Business</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box width="100%">
          <DataGrid
            rowCount={rowCount}
            rows={rowData}
            columns={columns}
            loading={loading}
            // onRowClick={getJobDetails}
            onRowClick={(row) => handleOpenJobDetails(row?.id)}
            pageSize={pageSize}
            onPageChange={getPage}
            page={page}
            autoHeight
            rowsPerPageOptions={[15]}
            paginationMode="server"
            getRowClassName={(params) =>
              params?.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
          />
          <Modal
            open={openJobDetails}
            onClose={handleCloseJobDetails}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={requiredWidth ? style : mobileStyle}>
              <SingleJobDetail job={checkJob} allArtisans={availableArtisans} />
            </Box>
          </Modal>
        </Box>
      </Box>
      <Dialog
        open={addJobDialogOpen}
        onClose={handleAddJobConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ padding: 3 }}
      >

        <Box sx={{ padding: '30px' }}>
          <DialogTitle id="alert-dialog-title"><strong>Post a Job request on behalf of a client?</strong></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box sx={{ marginTop: '-13px' }}>
                <p>You can post a job on behalf of a client and follow up on it. Note that your name will be attached to the job request for ease of tracking.</p>
                <RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                  <FormControlLabel value="individual" control={<Radio icon={<RadioButtonUncheckedIcon sx={{ color: '#FF5318', fontSize: 22 }} />} checkedIcon={<CheckCircleOutlineIcon sx={{ color: '#FF5318', fontSize: 22 }} />} />} label="Make an Individual Request" />
                  <FormControlLabel value="business" control={<Radio icon={<RadioButtonUncheckedIcon sx={{ color: '#FF5318', fontSize: 22 }} />} checkedIcon={<CheckCircleOutlineIcon sx={{ color: '#FF5318', fontSize: 22 }} />} />} label="Make a Business Request" />
                </RadioGroup>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              component={Link}
              to={getRedirectPath()}
              variant="contained"
              fullWidth
              disabled={!selectedOption}
              sx={{
                backgroundColor: selectedOption ? 'primary.main' : '#d3d3d3',
                color: selectedOption ? '#fff' : '#888',
                fontWeight: 'bold',
                borderRadius: '8px',
                padding: '12px 0',
                width: '100%',
              }}
            >
              Get Started
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default Index;
