import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, Grid, Button, Box, Typography } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { fetchAllPlans } from '../api';
import { createPlan, updatePlan } from '../api/customers';

function index() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const planId = queryParams.get('id');

    const [, setPlans] = useState([]);
    const [, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        price: '',
        features: '',
        icon_url: '',
        color: '',
        intro: '',
    });

    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchPlansAndSetForm = async () => {
            setLoading(true);
            const response = await fetchAllPlans();

            if (!response || response.error) {
                console.error('Failed to fetch plans:', response.error || 'No data received');
                setLoading(false);
                return;
            }

            console.log('Fetched Plans Data:', response);

            const plansArray = Array.isArray(response.data) ? response.data : [];
            setPlans(plansArray);

            if (planId) {
                const selectedPlan = plansArray.find(plan => String(plan.id) === String(planId));

                if (selectedPlan) {
                    console.log('Prefilling form with:', selectedPlan);

                    let parsedFeatures = [];
                    if (selectedPlan.features) {
                        try {
                            parsedFeatures = JSON.parse(selectedPlan.features);
                            if (!Array.isArray(parsedFeatures)) parsedFeatures = [];
                        } catch (error) {
                            console.warn('Failed to parse features:', error);
                            parsedFeatures = [];
                        }
                    }

                    setFormData({
                        name: selectedPlan.name || '',
                        price: selectedPlan.price || '',
                        features: parsedFeatures.length > 0 ? parsedFeatures.join('\n') : '',
                        icon_url: selectedPlan.icon_url || '',
                        color: selectedPlan.color || '',
                        intro: selectedPlan.intro || '',
                    });
                } else {
                    console.error('Plan ID not found.');
                }
            }

            setLoading(false);
        };

        fetchPlansAndSetForm();
    }, [planId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
    };

    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            const formattedData = {
                ...formData,
                features: formData.features ? formData.features.split('\n') : [],
            };

            let response;
            if (planId) {
                response = await updatePlan(planId, formattedData);
            } else {
                response = await createPlan(formattedData);
            }

            if (response.error) {
                console.error('Error saving plan:', response.error.message);
                setErrors(response.errors);
            } else {
                alert(`Plan ${planId ? 'updated' : 'created'} successfully`);
                navigate('/dashboard/artisans', { replace: true });
            }
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h5" fontWeight="bold" mb={2}>
                {planId ? 'Update Plan' : 'Create New Plan'}
            </Typography>

            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Plan Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Price (Kshs.)"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        error={!!errors.price}
                        helperText={errors.price}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Color"
                        name="color"
                        value={formData.color}
                        onChange={handleChange}
                        error={!!errors.color}
                        helperText={errors.color}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Intro"
                        name="intro"
                        value={formData.intro}
                        onChange={handleChange}
                        multiline
                        rows={1}
                        error={!!errors.intro}
                        helperText={errors.intro}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                        Features
                    </Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        name="features"
                        value={formData.features}
                        onChange={(e) => setFormData({ ...formData, features: e.target.value })}
                        placeholder="Enter features, each feature on a new line"
                        error={!!errors.features}
                        helperText={errors.features}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                        Upload Icon
                    </Typography>
                    <Box sx={{ border: '1px solid #ccc', borderRadius: 1, p: 2, textAlign: 'center', height: 90 }}>
                        <input type="file" accept="image/png, image/jpeg" id="icon-upload" style={{ display: 'none' }} onChange={handleFileChange} />
                        <label htmlFor="icon-upload">
                            <CloudUpload fontSize="large" sx={{ cursor: 'pointer' }} />
                            <Typography variant="body2">{file ? file.name : 'Upload an icon (png, jpeg)'}</Typography>
                        </label>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" fullWidth onClick={handleSubmit}>
                        {planId ? 'Update Plan' : 'Create Plan'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default index;
