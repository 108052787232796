import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Autocomplete,
  Alert,
  AlertTitle,
  InputAdornment,
} from '@mui/material';
import { CloudUpload, Add, Delete } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  createJobRequest,
  fetchAllCustomers,
  getLocations,
  getRequestTypes,
} from '../api';

function index() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceType = queryParams.get('type') || 'individual';
  const [reporterName, setReporterName] = useState('');
  const [requestTypes, setRequestTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerId] = useState('');
  const [, setCustomerSearch] = useState('');
  const [pageMessage, setPageMessage] = useState('');
  const [pageError, setPageError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isAllFormsFilled, setIsAllFormsFilled] = useState(false);
  const [searchFilter] = useState({});

  const navigate = useNavigate();

  const [jobRequests, setJobRequests] = useState([
    {
      jobType: '',
      schedule: null,
      address: '',
      location: '',
      description: '',
      file: null,
      urgent: false,
      selectedCustomer: null,
      ...(serviceType === 'business' && {
        jobType: '',
        schedule: new Date(),
        address: '',
        location: '',
        description: '',
        file: null,
        urgent: false,
        contactPersonPhone: '',
        businessWebsite: '',
        contactPerson: '',
        selectedCustomer: null,
      }),
    },
  ]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser?.first_name && storedUser?.last_name) {
      setReporterName(`${storedUser.first_name} ${storedUser.last_name}`);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLocations(await getLocations());
      setRequestTypes(await getRequestTypes());
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchFilteredCustomers = async () => {
      if (Object.keys(searchFilter).length === 0) {
        const response = await fetchAllCustomers();
        if (response?.success && response?.data?.data) {
          setCustomers(response.data.data);
        }
        return;
      }

      const response = await fetchAllCustomers({
        ...(searchFilter?.type === 'email'
          ? { email: searchFilter.value }
          : {}),
        ...(searchFilter?.type === 'name' ? { name: searchFilter.value } : {}),
        ...(searchFilter?.type === 'phone'
          ? { phone: searchFilter.value }
          : {}),
      });

      if (response?.success && response?.data?.data) {
        setCustomers(response.data.data);
      }
    };

    fetchFilteredCustomers();
  }, [searchFilter]);

  useEffect(() => {
    async function fetchData() {
      const types = await getRequestTypes();
      setRequestTypes(types || []);
    }
    fetchData();
  }, []);

  const updateJobRequest = (index, field, value) => {
    // Clear specific field error when updating that field
    if (fieldErrors[field]) {
      setFieldErrors((prev) => ({ ...prev, [field]: null }));
    }

    setJobRequests((prev) =>
      prev.map((job, i) => (i === index ? { ...job, [field]: value } : job))
    );
  };

  const handleFileChange = (e, index) => {
    updateJobRequest(index, 'file', e.target.files[0]);
  };

  const handleAddJobRequest = () => {
    setJobRequests((prevRequests) => [
      ...prevRequests,
      {
        jobType: '',
        schedule: new Date(),
        address: '',
        location: '',
        description: '',
        file: null,
        urgent: false,
        selectedCustomer: null,
        ...(serviceType === 'business' && {
          jobType: '',
          schedule: new Date(),
          address: '',
          location: '',
          description: '',
          file: null,
          urgent: false,
          contactPersonPhone: '',
          businessWebsite: '',
          contactPerson: '',
          selectedCustomer: null,
        }),
      },
    ]);
  };

  const handleDeleteJobRequest = (index) => {
    const updatedRequests = jobRequests.filter((_, i) => i !== index);
    setJobRequests(updatedRequests);
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Basic validation first
    if (!phoneNumber || phoneNumber.trim() === '') {
      return 'Phone number is required';
    }

    // For business phone numbers, we expect just the digits (without +254)
    // and we'll prepend +254 when submitting
    if (phoneNumber.length < 9) {
      return 'Phone number must be 9 digits';
    }

    if (!/^\d+$/.test(phoneNumber)) {
      return 'Phone number should contain only digits';
    }

    return null; // No error
  };

  const isFormComplete = (form) => {
    if (!form.selectedCustomer) return false;
    if (!form.jobType) return false;
    if (!(form.schedule instanceof Date)) return false;
    if (form.address.trim() === '') return false;
    if (form.location.trim() === '') return false;
    if (form.description.trim() === '') return false;

    // Additional checks for business service type
    if (serviceType === 'business') {
      if (form.contactPerson.trim() === '') return false;
      if (form.contactPersonPhone.trim() === '') return false;

      // Validate phone number format
      const phoneError = validatePhoneNumber(form.contactPersonPhone);
      if (phoneError) return false;
    }

    return true;
  };

  useEffect(() => {
    setIsAllFormsFilled(jobRequests.every(isFormComplete));
  }, [jobRequests]);

  useEffect(() => {
    if (customerId) {
      const customer = customers.find((c) => c.id === customerId);
      if (customer) setSelectedCustomer(customer);
    }
  }, [customers, customerId]);

  const handleSubmit = async () => {
    // Reset errors
    setPageError('');
    setFieldErrors({});

    if (!selectedCustomer) {
      setPageError('Please select a customer before submitting.');
      return;
    }

    // Validate all forms
    let hasErrors = false;
    const newFieldErrors = {};

    jobRequests.forEach((job, index) => {
      if (!job.jobType) {
        newFieldErrors[`jobType_${index}`] = 'Please select a job request type';
        hasErrors = true;
      }

      if (!job.location) {
        newFieldErrors[`location_${index}`] = 'Please select a location';
        hasErrors = true;
      }

      if (serviceType === 'business') {
        if (!job.contactPerson || job.contactPerson.trim() === '') {
          newFieldErrors[`contactPerson_${index}`] =
            'Contact person is required';
          hasErrors = true;
        }

        // Validate phone number
        const phoneError = validatePhoneNumber(job.contactPersonPhone);
        if (phoneError) {
          newFieldErrors[`contactPersonPhone_${index}`] = phoneError;
          hasErrors = true;
        }
      }
    });

    if (hasErrors) {
      setFieldErrors(newFieldErrors);
      setPageError('Please correct the errors before submitting.');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();

      jobRequests.forEach((job, index) => {
        if (serviceType === 'individual') {
          formData.append('customer_id', job.selectedCustomer.id);

          formData.append(`job_request[${index}][service_type]`, serviceType);
          formData.append(
            `job_request[${index}][request_type_id]`,
            job.jobType
          );
          formData.append(
            `job_request[${index}][schedule]`,
            job.schedule
              ? job.schedule.toISOString().slice(0, 19).replace('T', ' ')
              : ''
          );
          formData.append(
            `job_request[${index}][location_id]`,
            locations.find((loc) => loc.name === job.location)?.id || '7'
          );
          formData.append(`job_request[${index}][address]`, job.address);
          formData.append(
            `job_request[${index}][contact_person_name]`,
            `${job.selectedCustomer.first_name} ${job.selectedCustomer.last_name}`
          );
          formData.append(
            `job_request[${index}][contact_person_phone]`,
            job.selectedCustomer.phone || ''
          );
          formData.append(
            `job_request[${index}][description]`,
            job.description || ''
          );
          if (job.file) {
            formData.append(`job_request[${index}][file]`, job.file);
          }
        }

        if (serviceType === 'business') {
          formData.append('customer_id', job.selectedCustomer.id);
          formData.append(`job_request[${index}][service_type]`, serviceType);
          formData.append(
            `job_request[${index}][request_type_id]`,
            job.jobType
          );
          formData.append(
            `job_request[${index}][schedule]`,
            job.schedule
              ? job.schedule.toISOString().slice(0, 19).replace('T', ' ')
              : ''
          );
          formData.append(
            `job_request[${index}][location_id]`,
            locations.find((loc) => loc.name === job.location)?.id || '7'
          );
          formData.append(`job_request[${index}][address]`, job.address);
          formData.append(
            `job_request[${index}][business_website]`,
            job.businessWebsite || ''
          );
          formData.append(
            `job_request[${index}][contact_person_phone]`,
            `+254${job.contactPersonPhone}`
          );
          formData.append(
            `job_request[${index}][contact_person_name]`,
            job.contactPerson
          );
          formData.append(
            `job_request[${index}][description]`,
            job?.description || ''
          );
        }
      });

      const response = await createJobRequest(formData);
      setLoading(false);

      if (response.success) {
        setPageMessage('Job requests submitted successfully!');
        setPageError('');
        setTimeout(() => {
          navigate('/dashboard/job-requests');
        }, 3000);
        setJobRequests([
          {
            jobType: '',
            schedule: null,
            address: '',
            location: '',
            description: '',
            file: null,
            urgent: false,
            contactPersonPhone: '',
            businessWebsite: '',
            contactPerson: '',
          },
        ]);
      } else {
        if (response.error && response.error.message) {
          setPageError(response.error.message);
        } else if (response.exception_message) {
          setPageError(response.exception_message);
        } else if (response.data && response.data.exception_message) {
          setPageError(response.data.exception_message);
        } else {
          setPageError(
            'Failed to submit job request. Please check your input and try again.'
          );
        }

        if (response.data && response.data.data) {
          const apiErrors = {};

          Object.entries(response.data.data).forEach(([key, value]) => {
            if (key.includes('contact_person_phone')) {
              jobRequests.forEach((_, index) => {
                apiErrors[`contactPersonPhone_${index}`] = Array.isArray(value)
                  ? value[0]
                  : value;
              });
            }
          });

          setFieldErrors((prev) => ({ ...prev, ...apiErrors }));
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting job requests:', error);

      if (error.response && error.response.data) {
        if (error.response.data.exception_message) {
          setPageError(error.response.data.exception_message);
        } else if (error.response.data.message) {
          setPageError(error.response.data.message);
        } else {
          setPageError(
            'An error occurred while submitting your request. Please try again.'
          );
        }
      } else {
        setPageError('Failed to submit job requests. Please try again.');
      }
    }
  };

  const handleCustomerSearch = async (value) => {
    setCustomerSearch(value);

    if (value.trim() === '') {
      setCustomers([]);
      return;
    }

    let filter;
    if (value.includes('@')) {
      filter = { type: 'email', value };
    } else if (/^\+?\d+$/.test(value)) {
      filter = { type: 'phone', value };
    } else {
      filter = { type: 'name', value };
    }

    const response = await fetchAllCustomers({
      ...(filter?.type === 'email' ? { email: filter.value } : {}),
      ...(filter?.type === 'name' ? { name: filter.value } : {}),
      ...(filter?.type === 'phone' ? { phone: filter.value } : {}),
    });

    if (response?.success && response?.data?.data) {
      setCustomers(response.data.data);
    }
  };

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth="lg"
      p={5}
      minHeight="100vh"
    >
      {pageMessage && (
        <Alert severity="success" sx={{ mb: 3 }}>
          <AlertTitle>Success</AlertTitle>
          <strong>{pageMessage}</strong>
        </Alert>
      )}
      {pageError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          <AlertTitle>Error</AlertTitle>
          {pageError}
        </Alert>
      )}

      <Typography variant="h6" fontWeight="bold" mb={2}>
        {serviceType === 'business'
          ? 'Make a Business Request for a Client'
          : 'Make an Individual Job Request for a Client'}
      </Typography>

      {jobRequests.map((formData, index) => (
        <Box
          key={index}
          sx={{
            p: 2,
            borderRadius: '8px',
            position: 'relative',
            mb: 3,
            border: '1px solid #e0e0e0',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1" fontWeight="bold">
              {index === 0 ? 'Job Request' : `Job Request ${index + 1}`}
            </Typography>
            {jobRequests.length > 1 && (
              <IconButton
                onClick={() => handleDeleteJobRequest(index)}
                sx={{ color: 'red' }}
              >
                <Delete />
              </IconButton>
            )}
          </Box>

          <Grid container spacing={2}>
            {serviceType === 'individual' && (
              <>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    options={customers}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    value={jobRequests[index].selectedCustomer || null}
                    onChange={(event, newValue) => {
                      setSelectedCustomer(newValue);
                      updateJobRequest(index, 'selectedCustomer', newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleCustomerSearch(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Customer"
                        variant="outlined"
                        fullWidth
                        placeholder="Search by name, email, or phone number"
                        error={!!fieldErrors[`selectedCustomer_${index}`]}
                        helperText={fieldErrors[`selectedCustomer_${index}`]}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      sx={{
                        borderRadius: 3,
                        '& .MuiOutlinedInput-root': { borderRadius: 3 },
                        width: '100%',
                      }}
                      fullWidth
                      label="Customer Email"
                      value={formData.selectedCustomer?.email || ''}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={{
                        borderRadius: 3,
                        '& .MuiOutlinedInput-root': { borderRadius: 3 },
                        width: '100%',
                      }}
                      fullWidth
                      label="Customer Phone"
                      value={formData.selectedCustomer?.phone || ''}
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    options={requestTypes}
                    getOptionLabel={(option) => option?.name}
                    value={
                      requestTypes.find(
                        (type) => type.id === formData.jobType
                      ) || null
                    }
                    onChange={(event, newValue) =>
                      updateJobRequest(
                        index,
                        'jobType',
                        newValue ? newValue.id : null
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Job Request Type"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: undefined }}
                        error={!!fieldErrors[`jobType_${index}`]}
                        helperText={fieldErrors[`jobType_${index}`]}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Schedule"
                      sx={{
                        borderRadius: 3,
                        '& .MuiOutlinedInput-root': { borderRadius: 3 },
                        width: '100%',
                      }}
                      value={formData.schedule}
                      onChange={(newDate) =>
                        updateJobRequest(index, 'schedule', newDate)
                      }
                      minDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={!!fieldErrors[`schedule_${index}`]}
                          helperText={fieldErrors[`schedule_${index}`]}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    fullWidth
                    label="Enter your address"
                    value={formData.address}
                    onChange={(e) =>
                      updateJobRequest(index, 'address', e.target.value)
                    }
                    variant="outlined"
                    error={!!fieldErrors[`address_${index}`]}
                    helperText={fieldErrors[`address_${index}`]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    fullWidth
                    value={formData.location}
                    onChange={(e) =>
                      updateJobRequest(index, 'location', e.target.value)
                    }
                    displayEmpty
                    error={!!fieldErrors[`location_${index}`]}
                  >
                    <MenuItem value="" disabled>
                      Select your Location
                    </MenuItem>
                    {locations.map((location) => (
                      <MenuItem key={location.id} value={location.name}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldErrors[`location_${index}`] && (
                    <Typography color="error" variant="caption">
                      {fieldErrors[`location_${index}`]}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    fullWidth
                    label="Job description"
                    value={formData.description}
                    onChange={(e) =>
                      updateJobRequest(index, 'description', e.target.value)
                    }
                    multiline
                    rows={4}
                    variant="outlined"
                    error={!!fieldErrors[`description_${index}`]}
                    helperText={fieldErrors[`description_${index}`]}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      padding: '20px',
                      textAlign: 'center',
                      height: '82px',
                    }}
                  >
                    <input
                      type="file"
                      accept="image/png, image/jpeg, application/pdf"
                      style={{ display: 'none' }}
                      id={`file-upload-${index}`}
                      onChange={(e) => handleFileChange(e, index)}
                    />
                    <label htmlFor={`file-upload-${index}`}>
                      <CloudUpload
                        fontSize="large"
                        sx={{ cursor: 'pointer' }}
                      />
                      <Typography variant="body2">
                        Upload job-related file
                      </Typography>
                      {formData.file ? (
                        <Typography variant="body2" mt={1} color="green">
                          {formData.file.name}
                        </Typography>
                      ) : (
                        <Typography variant="caption" color="error">
                          (png, jpeg, pdf supported)
                        </Typography>
                      )}
                    </label>
                  </Box>
                </Grid>
              </>
            )}

            {serviceType === 'business' && (
              <>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    options={customers}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name}`
                    }
                    value={jobRequests[index].selectedCustomer || null}
                    onChange={(event, newValue) => {
                      setSelectedCustomer(newValue);
                      updateJobRequest(index, 'selectedCustomer', newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleCustomerSearch(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Customer"
                        variant="outlined"
                        fullWidth
                        placeholder="Search by name, email, or phone number"
                        error={!!fieldErrors[`selectedCustomer_${index}`]}
                        helperText={fieldErrors[`selectedCustomer_${index}`]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    fullWidth
                    label="Customer Email"
                    value={formData.selectedCustomer?.email || ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    fullWidth
                    label="Customer Phone"
                    value={formData.selectedCustomer?.phone || ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact Person"
                    value={formData.contactPerson}
                    onChange={(e) =>
                      updateJobRequest(index, 'contactPerson', e.target.value)
                    }
                    variant="outlined"
                    error={!!fieldErrors[`contactPerson_${index}`]}
                    helperText={fieldErrors[`contactPerson_${index}`]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Schedule"
                      sx={{
                        borderRadius: 3,
                        '& .MuiOutlinedInput-root': { borderRadius: 3 },
                        width: '100%',
                      }}
                      value={formData.schedule}
                      onChange={(newDate) =>
                        updateJobRequest(index, 'schedule', newDate)
                      }
                      minDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          error={!!fieldErrors[`schedule_${index}`]}
                          helperText={fieldErrors[`schedule_${index}`]}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact Person Phone"
                    placeholder="700000000"
                    value={formData.contactPersonPhone}
                    onChange={(e) => {
                      // Remove any non-digit characters and limit to 9 digits
                      const cleanedInput = e.target.value
                        .replace(/\D/g, '')
                        .slice(0, 9);
                      updateJobRequest(
                        index,
                        'contactPersonPhone',
                        cleanedInput
                      );
                    }}
                    variant="outlined"
                    error={!!fieldErrors[`contactPersonPhone_${index}`]}
                    helperText={
                      fieldErrors[`contactPersonPhone_${index}`] ||
                      'Enter 9-digit number without country code'
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+254</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    options={requestTypes}
                    getOptionLabel={(option) => option?.name}
                    value={
                      requestTypes.find(
                        (type) => type.id === formData.jobType
                      ) || null
                    }
                    onChange={(event, newValue) =>
                      updateJobRequest(
                        index,
                        'jobType',
                        newValue ? newValue.id : null
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Job Request Type"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: undefined }}
                        error={!!fieldErrors[`jobType_${index}`]}
                        helperText={fieldErrors[`jobType_${index}`]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    fullWidth
                    label="Enter your address"
                    value={formData.address}
                    onChange={(e) =>
                      updateJobRequest(index, 'address', e.target.value)
                    }
                    variant="outlined"
                    error={!!fieldErrors[`address_${index}`]}
                    helperText={fieldErrors[`address_${index}`]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    fullWidth
                    value={formData.location}
                    onChange={(e) =>
                      updateJobRequest(index, 'location', e.target.value)
                    }
                    displayEmpty
                    error={!!fieldErrors[`location_${index}`]}
                  >
                    <MenuItem value="" disabled>
                      Select your Location
                    </MenuItem>
                    {locations.map((location) => (
                      <MenuItem key={location.id} value={location.name}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldErrors[`location_${index}`] && (
                    <Typography color="error" variant="caption">
                      {fieldErrors[`location_${index}`]}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Business Website"
                    value={formData.businessWebsite}
                    onChange={(e) =>
                      updateJobRequest(index, 'businessWebsite', e.target.value)
                    }
                    variant="outlined"
                    error={!!fieldErrors[`businessWebsite_${index}`]}
                    helperText={fieldErrors[`businessWebsite_${index}`]}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{
                      borderRadius: 3,
                      '& .MuiOutlinedInput-root': { borderRadius: 3 },
                      width: '100%',
                    }}
                    fullWidth
                    label="Job description"
                    value={formData.description}
                    onChange={(e) =>
                      updateJobRequest(index, 'description', e.target.value)
                    }
                    multiline
                    rows={4}
                    variant="outlined"
                    error={!!fieldErrors[`description_${index}`]}
                    helperText={fieldErrors[`description_${index}`]}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      padding: '20px',
                      textAlign: 'center',
                      height: '82px',
                    }}
                  >
                    <input
                      type="file"
                      accept="image/png, image/jpeg, application/pdf"
                      style={{ display: 'none' }}
                      id={`file-upload-${index}`}
                      onChange={(e) => handleFileChange(e, index)}
                    />
                    <label htmlFor={`file-upload-${index}`}>
                      <CloudUpload
                        fontSize="large"
                        sx={{ cursor: 'pointer' }}
                      />
                      <Typography variant="body2">
                        Upload job-related file
                      </Typography>
                      {formData.file ? (
                        <Typography variant="body2" mt={1} color="green">
                          {formData.file.name}
                        </Typography>
                      ) : (
                        <Typography variant="caption" color="error">
                          (png, jpeg, pdf supported)
                        </Typography>
                      )}
                    </label>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>

          <Box mt={2} display="flex" justifyContent="space-between">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.urgent}
                  onChange={(e) =>
                    updateJobRequest(index, 'urgent', e.target.checked)
                  }
                />
              }
              label="Mark as Urgent"
            />
            <Typography variant="body2" fontWeight="bold">
              Reporter: {reporterName}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            color="primary.main"
            sx={{ cursor: 'pointer', mt: 1 }}
            onClick={handleAddJobRequest}
          >
            <Add sx={{ fontSize: '20px', marginLeft: '15px' }} />
            <Typography variant="body2" fontWeight="bold">
              Add a job request
            </Typography>
          </Box>
        </Box>
      ))}

      <Button
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        disabled={!isAllFormsFilled || loading}
        sx={{
          backgroundColor: isAllFormsFilled ? '#f4511e' : '#ccc',
          color: '#fff',
          fontWeight: 'bold',
          borderRadius: '8px',
          padding: '12px 0',
          fontSize: '16px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: isAllFormsFilled ? '#d04018' : '#ccc',
          },
        }}
      >
        {loading ? 'Processing...' : 'Place Job Request'}
      </Button>
    </Box>
  );
}

export default index;
