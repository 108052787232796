import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  Home,
  AllJobRequests,
  AppManagement,
  Roles,
  AllCustomers,
  AllArtisans,
  AllQuotations,
  Quotation,
  AllReceipts,
  Assessment,
  AssessmentPage,
  Notfound,
  AdminManagement,
  AssessorManagement,
  AllInvoices,
  CreateAJobRequest,
  AllSubscriptions,
  SubscriptionPlan
} from './pages';
import { ThemeProvider } from '@mui/material';
// import { LocalizationProvider } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import theme from './theme';
import Dashboard from './pages/Dashboard';
import { AuthProvider } from './context/useAuth';
import { Protected, DashboardCard } from './components';
import { AxiosInterceptor } from './_helpers/axios.interceptors';

AxiosInterceptor();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="dashboard"
                element={
                  <Protected redirectPath="/">
                    <Dashboard />
                  </Protected>
                }
              >
                <Route
                  path="job-requests"
                  element={
                    <Protected redirectPath="/">
                      <AllJobRequests />
                    </Protected>
                  }
                />
                <Route
                  path="job-requests/create-a-job-request"
                  element={<Protected redirectPath="/">
                    <CreateAJobRequest />
                  </Protected>}
                />

                <Route
                  path="subscription/plan"
                  element={<Protected redirectPath="/">
                    <SubscriptionPlan />
                  </Protected>}
                />

                <Route
                  index
                  element={
                    <Protected redirectPath="/">
                      <DashboardCard />
                    </Protected>
                  }
                />
                <Route
                  path="all-customers"
                  element={
                    <Protected redirectPath="/">
                      <AllCustomers />
                    </Protected>
                  }
                />
                <Route
                  path="artisans"
                  element={
                    <Protected redirectPath="/">
                      <AllArtisans />
                    </Protected>
                  }
                />
                <Route
                  path="quotations"
                  element={
                    <Protected redirectPath="/">
                      <AllQuotations />
                    </Protected>
                  }
                />
                <Route
                  path="invoices"
                  element={
                    <Protected redirectPath="/">
                      <AllInvoices />
                    </Protected>
                  }
                />
                <Route
                  path="quotations/:id"
                  element={
                    <Protected redirectPath="/">
                      <Quotation />
                    </Protected>
                  }
                />
                <Route
                  path="receipt"
                  element={
                    <Protected redirectPath="/">
                      <AllReceipts />
                    </Protected>
                  }
                />
                <Route
                  path="app-management"
                  element={
                    <Protected redirectPath="/">
                      <AppManagement />
                    </Protected>
                  }
                />
                <Route
                  path="subscriptions"
                  element={
                    <Protected redirectPath="/">
                      <AllSubscriptions />
                    </Protected>
                  }
                />
                <Route
                  path="assessments"
                  element={
                    <Protected redirectPath="/">
                      <AssessmentPage />
                    </Protected>
                  }
                />
                <Route
                  path="assessments/:id"
                  element={
                    <Protected redirectPath="/">
                      <Assessment />
                    </Protected>
                  }
                />

                <Route
                  path="assessor-management"
                  element={
                    <Protected redirectPath="/">
                      <AssessorManagement />
                    </Protected>
                  }
                />

                <Route
                  path="admin-management"
                  element={
                    <Protected redirectPath="/">
                      <AdminManagement />
                    </Protected>
                  }
                />

                <Route
                  path="roles-functions"
                  element={
                    <Protected redirectPath="/">
                      <Roles />
                    </Protected>
                  }
                />
                <Route
                  path="assessments"
                  element={<Protected redirectPath="/"></Protected>}
                />

                <Route
                  path="job-requests"
                  element={<Protected redirectPath="/"></Protected>}
                />

              </Route>

              {/* <Route
                  path="profile"
                  element={
                    <Protected redirectPath="/">
                      <Profile />
                    </Protected>
                  }
                />
              </Route>
              <Route path="/verification" element={<VerifyPhone />} />
              <Route path="/verify-otp" element={<VerifyOTP />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              */}
              <Route path="*" exact element={<Notfound />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
