/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Modal,
  Grid,
  Button,
  Tabs,
  Tab,
  TextField,
} from '@mui/material';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  exportAdminData,
  fetchAllQuotations,
  fetchQuotation,
  fetchAllExternalQuotations,
  fetchExternalQuotation,
  searchAllQuotations,
  searchAllExternalQuotations,
} from '../api';
import { Quotation, DataGrid } from '../components';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // 0 for internal, 1 for external
  const [searchTerm, setSearchTerm] = useState('');

  const [openQuotationDetails, setOpenQuotationDetails] = useState(false);
  const [checkQuotation, setcheckQuotation] = useState([]);

  const handleCloseQuotationDetails = () => setOpenQuotationDetails(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(0); // Reset to first page when switching tabs
    if (searchTerm) {
      handleSearch();
    } else {
      getQuotation(0, newValue); // Fetch data for the new tab
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    overflowY: 'auto',
    maxHeight: '80vh',
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left',
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    maxHeight: '70vh',
    width: '80vw',
    minHeight: '30vh',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  const columns = [
    { field: 'reference', headerName: 'Reference', width: 300 },
    {
      field: 'status',
      headerName: 'Status',
      width: 300,
    },
    {
      field: 'date_created',
      headerName: 'Created Date',
      width: 200,
    },
    {
      field: 'date_updated',
      headerName: 'Updated Date',
      width: 160,
    },
  ];

  const getOneRequest = async (id, tabIndex = activeTab) => {
    setLoading(true);
    const quotationType = tabIndex === 0 ? 'internal' : 'external';
    let response;
    if (quotationType === 'internal') {
      response = await fetchQuotation(id);
    } else if (quotationType === 'external') {
      response = await fetchExternalQuotation(id);
    }
    setLoading(false);

    if (response?.success && response?.data) {
      setcheckQuotation(response?.data);
    }
  };

  const handleOpenQuotationDetails = (id) => {
    getOneRequest(id);
    setOpenQuotationDetails(true);
  };

  const getQuotation = async (page, tabIndex = activeTab) => {
    setLoading(true);
    const quotationType = tabIndex === 0 ? 'internal' : 'external';
    let response;

    if (quotationType === 'internal') {
      response = await fetchAllQuotations(page + 1, quotationType);
    } else if (quotationType === 'external') {
      response = await fetchAllExternalQuotations(page + 1, quotationType);
    }
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((customerData) => ({
        ...customerData,
        date_created: moment(customerData?.created_at).format('ll'),
        date_updated: moment(customerData?.updated_at).format('ll'),
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      getQuotation(0, activeTab);
      return;
    }

    setLoading(true);
    let response;

    try {
      const searchQuery = `search?param=${searchTerm}`;
      if (activeTab === 0) {
        response = await searchAllQuotations(searchQuery);
      } else {
        response = await searchAllExternalQuotations(searchQuery);
      }

      if (response?.success && response?.data?.data) {
        const { data } = response;
        const rows = data?.data.map((customerData) => ({
          ...customerData,
          date_created: moment(customerData?.created_at).format('ll'),
          date_updated: moment(customerData?.updated_at).format('ll'),
        }));

        setRowData(rows);
        setRowCount(data?.total);
        setPage(0);
      } else {
        toast.error('Search failed. Please try again.');
      }
    } catch (error) {
      console.error('Search failed:', error);
      toast.error('Search failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const getPage = async (page) => {
    if (searchTerm.trim()) {
      // If there's a search term, don't paginate
      return;
    }
    await getQuotation(page);
    setPage(page);
  };

  const handleExport = async () => {
    setLoading(true);
    const quotationType = activeTab === 0 ? 'internal' : 'external';
    const response = await exportAdminData(`quotations/${quotationType}`);

    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');

    a.download = `${quotationType}Quotations_export_output_${date}.csv`;
    a.href = response?.data?.report;

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    setLoading(false);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    getQuotation(0, activeTab);
  };

  useEffect(() => {
    if (!searchTerm.trim()) {
      getQuotation(page);
    }
  }, [page]);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <ToastContainer />
      <Grid container spacing={2} mb={3}>
        <Grid item lg={4}>
          <Typography variant="h5">All Quotations</Typography>
        </Grid>
        <Grid item lg={6}>
          <Box display="flex" gap={2}>
            <TextField
              size="small"
              placeholder="Search quotations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              InputProps={{
                endAdornment: searchTerm && (
                  <ClearIcon
                    sx={{ cursor: 'pointer', color: 'text.secondary' }}
                    onClick={handleClearSearch}
                  />
                ),
              }}
              sx={{ width: '100%' }}
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
              sx={{
                px: 5,
              }}
            >
              Search
            </Button>
          </Box>
        </Grid>
        <Grid item lg={2}>
          <Button
            variant="outlined"
            sx={{ width: '100%' }}
            onClick={() => handleExport()}
          >
            Export
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Internal Quotations" />
          <Tab label="External Quotations" />
        </Tabs>
      </Box>

      <Box width="100%">
        <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          onRowClick={(row) => handleOpenQuotationDetails(row?.id)}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[15]}
          paginationMode="server"
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
        <Modal
          open={openQuotationDetails}
          onClose={handleCloseQuotationDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={requiredWidth ? style : mobileStyle}>
            <div style={{ maxHeight: '100%', overflow: 'auto' }}>
              <Quotation quotation={checkQuotation} />
            </div>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default Index;
