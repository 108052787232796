/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Grid,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputAdornment,
  CardContent,
  Card,
  Modal
} from '@mui/material';

import moment from 'moment';
import { DataGrid } from '../components';
import {
  fetchAllCustomers,
  exportUserData,
  deactivateOneCustomer,
  activateOneCustomer,
  deleteOneCustomer,
} from '../api';
import { AddCircle } from '@mui/icons-material';
import validator from 'validator';
import { createAdmin } from '../api/roleManagement';
import phone from 'phone';
import { useForm } from 'react-hook-form';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [pageMessage, setPageMessage] = useState('');
  const [pageError, setPageError] = useState();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState({});
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      user_type: '',
      role: ''
    }
  });

  const userTypeOptions = ['customer'];
  const userType = localStorage.getItem('user-role');
  const selectedUserType = watch('user_type');

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const columns = [
    { field: 'name', headerName: 'Customer Name', width: 250 },
    {
      field: 'email',
      headerName: 'Email',
      width: 250
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 200
    },
    {
      field: 'verified',
      headerName: 'Verified',
      width: 160
    },
    {
      field: 'action',
      headerName: 'Deactivate account',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const onClickDeactivate = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          await deactivateCustomer(params?.id);
          window.location.reload();
        };

        const onClickActivate = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          await activateCustomer(params?.id);
          window.location.reload();
        };

        if (params?.row?.is_active === true) {
          return <Button onClick={onClickDeactivate}>Deactivate</Button>;
        } else {
          return <Button onClick={onClickActivate}>Activate</Button>;
        }
      }
    },
    {
      field: 'delete',
      headerName: 'Delete account',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const onClickDelete = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          await deleteCustomer(params?.id);
          window.location.reload();
        };

        return (
          <div>
            <Button onClick={() => setConfirmDelete(true)}>
              Delete account
            </Button>
            <Dialog
              open={confirmDelete}
              onClose={() => setConfirmDelete(false)}
              aria-labelledby="confirm-dialog"
            >
              <DialogTitle>Delete Customer</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this customer account?
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                  color="secondary"
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setConfirmDelete(false);
                    onClickDelete(e);
                  }}
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
    }
  ];

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      getAllCustomers(page);
      setSearchFilter({});
      return;
    }
    let categorize;
    if (searchTerm.includes('@')) {
      categorize = { type: 'email', value: searchTerm };
    } else if (/^\+?\d+$/.test(searchTerm)) {
      // Matches a string that is fully numeric with an optional leading '+'
      categorize = { type: 'phone', value: searchTerm };
    } else {
      categorize = { type: 'name', value: searchTerm };
    }
    setSearchFilter(categorize);
  };

  const getAllCustomers = async (page) => {
    setLoading(true);
    console.log(searchFilter);
    const response = await fetchAllCustomers({
      page: page + 1,
      ...(searchFilter?.type === 'email' ? { email: searchFilter.value } : {}),
      ...(searchFilter?.type === 'name' ? { name: searchFilter.value } : {}),
      ...(searchFilter?.type === 'phone' ? { phone: searchFilter.value } : {}),
    });
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((customerData) => ({
        ...customerData,
        name: `${customerData?.first_name} ${customerData?.last_name}`,
        verified: customerData?.phone_verified
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const deactivateCustomer = async (id) => {
    setLoading(true);
    const response = await deactivateOneCustomer(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('User account has been deacticated.');
    } else {
      setPageError('There is a problem with this account');
    }
  };

  const activateCustomer = async (id) => {
    setLoading(true);
    const response = await activateOneCustomer(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('User account has been reactivated.');
    } else {
      setPageError('You are unable to activate this account at the moment.');
    }
  };

  const deleteCustomer = async (id) => {
    setLoading(true);
    const response = await deleteOneCustomer(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('User account has been DELETED.');
    } else {
      setPageError('You are unable to delete this account at the moment.');
    }
  };

  const handleExport = async () => {
    setLoading(true);

    const response = await exportUserData('customer');

    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');

    a.download = `AllCustomers_export_output_${date}.csv`;
    a.href = response?.data?.report;

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    setLoading(false);
  };

  const getPage = async (page) => {
    await getAllCustomers(page);
    setPage(page);
  };

  useEffect(() => {
    getAllCustomers(page);
  }, [page, searchFilter]);

  const handleAddMore = () => {
    setOpen(true);
  };

  const addMore = async (data) => {
    if (data.password === data.confirm_password) {
      setLoading(true);
      const response = await createAdmin({
        ...data,
        phone: phone(`+254-${data.phone}`).phoneNumber,
        user_type: data.user_type,
      });

      setLoading(false);

      if (response?.error) {
        const { error } = response;

        setPageError(error?.message);
        return;
      }

      if (response?.success) {
        setPageMessage(
          `Successfully created an account for ${response?.data?.first_name}`
        );
        setOpen(false);
      }
      window.location.reload();
    } else {
      setPageError('Password and Confirm password do not match');
    }
  };

  return (
    <>
      <Grid container>
        {pageMessage && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            <strong>{pageMessage}</strong>
          </Alert>
        )}

        {pageError && <Alert severity="error">{pageError}</Alert>}

        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth={{ lg: 'lg', xl: 'lg' }}
          p={5}
          minHeight="100vh"
          container
          flexGrow={1}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={4}>
            <Button
              variant="outlined"
              fontWeight="bold"
              onClick={() => handleAddMore()}
              // disabled={userType !== '"super-admin"'}
            >
              <AddCircle /> Add New Customer
            </Button>
          </Box>
          <Grid container justifyContent='space-between'>
            <Grid item lg={6}>
              <Typography variant="h5" mb={3}>
                All Customers
              </Typography>
            </Grid>
            <Grid item lg={6} sm={4} md={4}>
              <Button
                // p b={2}
                variant="outlined"
                sx={{ width: 300, float: 'right' }}
                onClick={() => handleExport()}
              >
                Export
              </Button>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mb={3}>
            <Box display="flex" sx={{ flexGrow: 1, gap: 1 }}>
              <form
                style={{ display: 'flex', flexGrow: 1, gap: 1 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
              >
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  placeholder='Search by name or email or phone number'
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </form>
            </Box>
            {/* <FormControl sx={{ width: 100 }}>
            <InputLabel id="filter-label">Gender</InputLabel>
            <Select
              labelId="filter-label"
              id="filter-select"
              value={genderFilter}
              onChange={(e) => setGenderFilter(e.target.value)}
              label="Filter by Gender"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
          </FormControl> */}
          </Box>

          <Box width="100%">
            <DataGrid
              rowCount={rowCount}
              rows={rowData}
              columns={columns}
              loading={loading}
              pageSize={pageSize}
              onPageChange={getPage}
              page={page}
              autoHeight
              rowsPerPageOptions={[15]}
              paginationMode="server"
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
            />
          </Box>
        </Box>
      </Grid>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { lg: 600, md: 300 }, padding: 'auto' }}>
          {pageError && <Alert severity="error">{pageError}</Alert>}
          {pageMessage && <Alert severity="success">{pageMessage}</Alert>}
          <Grid mb={1}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Register New Customer
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(addMore)}>
            <CardContent sx={{ padding: 5 }}>
              <Grid container>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="First Name"
                    required={true}
                    fullWidth
                    {...register('first_name', {
                      required: {
                        value: true,
                        message: 'Field is required'
                      }
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.first_name?.message}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="Last Name"
                    required={true}
                    fullWidth
                    {...register('last_name', {
                      required: {
                        value: true,
                        message: 'Field is required'
                      }
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.last_name?.message}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="Email"
                    required={true}
                    fullWidth
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'email is required'
                      },

                      validate: (value) => validator.isEmail(value)
                    })}
                  />
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.email?.message}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    type="tel"
                    label="Phone Number"
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+254</InputAdornment>
                      )
                    }}
                    {...register('phone', {
                      required: {
                        value: true,
                        message: 'Phone number is required'
                      },
                      maxLength: {
                        value: 10,
                        message: 'Phone number cannot exceed 11 digits'
                      },
                      validate: (value) =>
                        validator.isMobilePhone(value) &&
                        phone(`+254-${value}`).isValid
                    })}
                    required={!!errors.phone}
                    fullWidth
                  />
                  <Typography variant="small" component="small" color="error">
                    {errors.phone?.message}
                    {errors.phone &&
                      errors.phone.type === 'validate' &&
                      'Enter a valid phone number'}
                  </Typography>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    type={'text'}
                    label="Password"
                    variant="standard"
                    required={true}
                    {...register('password', {
                      required: {
                        value: true,
                        message: 'Password cannot be empty'
                      },
                      minLength: {
                        value: 8,
                        message: 'Password cannot be less than 8 characters'
                      },
                      validate: (value) =>
                        validator.isStrongPassword(value, {
                          minLength: 8,
                          minLowercase: 1,
                          minUppercase: 1,
                          minNumbers: 1,
                          minSymbols: 0
                        })
                    })}
                    fullWidth
                  />
                  <Typography variant="small" component="small" color="error">
                    {errors.password?.message}
                    {errors.password &&
                      errors.password.type === 'validate' &&
                      'Password must contain a lowercase (a-z), an uppercase (A-Z) and a number (0-9)'}
                  </Typography>
                </Grid>
                <Grid item lg={6} p={2}>
                  <TextField
                    type={'text'}
                    label="Confirm Password"
                    variant="standard"
                    required={true}
                    {...register('confirm_password', {
                      required: {
                        value: true,
                        message: 'Password cannot be empty'
                      },
                      minLength: {
                        value: 8,
                        message: 'Password cannot be less than 8 characters'
                      },
                      validate: (value) =>
                        validator.isStrongPassword(value, {
                          minLength: 8,
                          minLowercase: 1,
                          minUppercase: 1,
                          minNumbers: 1,
                          minSymbols: 0
                        })
                    })}
                    fullWidth
                  />
                  <Typography variant="small" component="small" color="error">
                    {errors.password?.message}
                    {errors.password &&
                      errors.password.type === 'validate' &&
                      'Password must contain a lowercase (a-z), an uppercase (A-Z) and a number (0-9)'}
                  </Typography>
                </Grid>

                <Grid item lg={6} p={2}>
                  <TextField
                    variant="standard"
                    label="User Type"
                    select
                    fullWidth
                    {...register('user_type', {
                      required: 'User type is required'
                    })}
                    error={!!errors.user_type}
                  >
                    {userTypeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {errors.user_type?.message}
                    </Typography>
                  </Box>
                </Grid>

              </Grid>
            </CardContent>
            <Grid mt={3}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ width: 300, margin: 'auto' }}
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Card>
      </Modal>
    </>
  );
}

export default Index;
